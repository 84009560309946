<template>
  <div>
    <b-alert v-if="errorMessage" show dismissible fade variant="danger"
      >Failed to get records: {{ errorMessage }}</b-alert
    >
    <b-alert :show="userAlert" dismissible @dismissed="userAlert = false" fade variant="success"
      >Successfully {{ userOperation }} user {{ userFirstName }} {{ userLastName }}</b-alert
    >
    <SearchHeader ref="search">
      <template #afterSearch>
        <div class="flex flex-wrap after-search">
          <InviteUserModal @onInvited="onInvitedUser" class="px-2 d-inline" />
          <b-form inline>
            <b-checkbox name="show-paid" v-model="hideDeletedUsers" switch />
            <label for="show-paid">Hide Deleted Users</label>
          </b-form>
        </div>
      </template>
    </SearchHeader>
    <b-table
      show-empty
      id="users-table"
      class="users-table"
      striped
      sort-icon-left
      :fields="tableFields"
      :per-page="table.perPage"
      :current-page="table.currentPage"
      :items="items"
      :sort-by.sync="table.sortBy"
      :sort-desc.sync="table.sortDesc"
    >
      <template v-slot:table-busy>
        <div class="text-center">
          <LoadingSpinner class="m-auto" />
        </div>
      </template>
      <template #cell(edit)="row">
        <span v-if="!row.item.deleted" v-b-modal="row.item.id">
          <i class="fa-regular fa-pen-to-square cursor-pointer"></i>
        </span>
      </template>
      <template v-slot:cell(lastLogin)="row">
        <span v-if="row.item.lastLogin" v-b-tooltip.hover.html="tipMethod(row.item)" class="dashline">
          {{ formatDateTime(parseISO(row.item.lastLogin)) }}
        </span>
      </template>
      <template #cell(status)="row">
        <b-badge v-if="row.item.deleted" variant="dark">Deleted</b-badge>
        <b-badge v-else-if="row.item.disabled" variant="warning">Disabled</b-badge>
        <b-badge v-else-if="row.item.frozen" variant="danger">Frozen</b-badge>
        <b-badge v-else-if="!row.item.emailVerified" variant="warning">Unverified</b-badge>
        <b-badge v-else variant="success">Active</b-badge>
      </template>
      <template #cell(firebase)="row">
        <b-badge v-if="row.item.firebase" variant="success">Yes</b-badge>
        <b-badge v-else variant="secondary">No</b-badge>
      </template>
      <template #cell(actions)="row">
        <div class="flex justify-end">
          <button
            class="p-2 focus:ring focus:ring-green-200 rounded-lg button-link"
            v-b-modal="row.item.id"
            v-b-tooltip.hover
            title="Edit User"
          >
            <EditUserModal @onEdited="onEditUser" :modalId="row.item.id" :user="row.item" />
            <font-awesome-icon class="text-xl" :icon="['far', 'pen-to-square']" />
            <span class="sr-only">Edit User</span>
          </button>

          <router-link
            :to="{ name: 'User', query: { id: row.item.id } }"
            class="p-2 focus:ring focus:ring-green-200 rounded-lg button-link"
            v-b-tooltip.hover
            title="View User"
          >
            <font-awesome-icon class="text-xl" :icon="['far', 'eye']" />
            <span class="sr-only">View User</span>
          </router-link>

          <button
            @onDeleted="onDeleteUser"
            @click="confirmDelete(row, $event)"
            class="p-2 focus:ring focus:ring-green-200 rounded-lg"
            v-b-tooltip.hover
            title="Delete User"
          >
            <font-awesome-icon class="text-xl" :icon="['far', 'trash-can']" />
            <span class="sr-only">Delete User</span>
          </button>
        </div>
      </template>
    </b-table>
    <b-tfoot>
      <b-tr>
        <b-td colspan="7" class="text-right">
          <b-form inline>
            <div class="mr-4">
              Total Rows:
              <strong>{{ table.rows }}</strong>
            </div>
          </b-form>
        </b-td>
      </b-tr>
    </b-tfoot>
    <b-pagination
      v-model="table.currentPage"
      :total-rows="table.rows"
      :per-page="table.perPage"
      aria-controls="table"
      pills
      align="center"
      first-text="First"
      prev-text="Prev"
      next-text="Next"
      last-text="Last"
    ></b-pagination>
  </div>
</template>

<script>
import { getAuth } from '@rafflebox-technologies-inc/auth-service-sdk';
import EditUserModal from '@/components/EditUserModal';
import UserServiceV2 from '@/lib/user-service-v2';
import SearchHeader from '@/components/SearchHeader';
import InviteUserModal from '@/components/InviteUserModal.vue';

import OrganizationService from '@/lib/organization-service-v2';
import LoadingSpinner from '@/components/rbComponents/LoadingSpinner.vue';

export default {
  name: 'Users',
  components: {
    InviteUserModal,
    EditUserModal,
    SearchHeader,
    LoadingSpinner
  },
  props: ['includeAllOrganizations'],
  data() {
    return {
      search: '',
      hideDeletedUsers: true,
      userOperation: null,
      userAlert: null,
      userFirstName: null,
      userLastName: null,
      errorMessage: null,
      alert: {
        text: '',
        model: false
      },
      table: {
        rows: 0,
        currentPage: 1,
        perPage: 10
      }
    };
  },
  computed: {
    tableFields() {
      const fields = [{ key: 'id', thClass: 'd-none', tdClass: 'd-none' }];

      if (this.includeAllOrganizations) {
        fields.push({ key: 'organization', label: 'Organization Name', sortable: true });
      }

      fields.push(
        { key: 'firstName', sortable: true },
        { key: 'lastName', sortable: true },
        { key: 'username', sortable: true },
        { key: 'email', sortable: true },
        {
          key: 'lastLogin',
          sortable: true,
          sortByFormatted: true,
          formatter: (val) => {
            return val ? this.formatDateTime(this.parseISO(val)) : '';
          }
        },
        { key: 'status' },
        { key: 'firebase', label: 'v2' }
      );

      fields.push({
        key: 'actions',
        label: ''
      });

      return fields;
    }
  },
  watch: {
    search: function () {
      this.refreshTable();
    },
    hideDeletedUsers: function () {
      this.refreshTable();
    },
    'table.sortBy': function () {
      this.refreshTable();
    }
  },
  async mounted() {
    this.$watch('$refs.search.search', (newVal) => (this.search = newVal));
  },
  methods: {
    onInvitedUser(payload) {
      this.userAlert = true;
      this.userOperation = 'invited';
      this.userFirstName = payload.firstName;
      this.userLastName = payload.lastName;
      this.refreshTable();
    },
    onCreatedUser(payload) {
      this.userAlert = true;
      this.userOperation = 'created';
      this.userFirstName = payload.firstName;
      this.userLastName = payload.lastName;
      this.refreshTable();
    },
    onDeleteUser(payload) {
      this.userAlert = true;
      this.userOperation = 'deleted';
      this.userFirstName = payload.firstName;
      this.userLastName = payload.lastName;
      this.refreshTable();
    },
    onEditUser(payload) {
      this.userAlert = true;
      this.userOperation = 'edited';
      this.userFirstName = payload.firstName;
      this.userLastName = payload.lastName;
      this.refreshTable();
    },
    refreshTable() {
      this.$root.$emit('bv::refresh::table', 'users-table');
    },
    async items() {
      const sessionUser = await getAuth().sessionUser();
      const organizationId = sessionUser.organizationUuid;
      const params = {
        search: this.search.length > 0 ? this.search : undefined,
        page: this.table.currentPage - 1,
        pageSize: 10,
        sortBy: this.table.sortBy || undefined,
        sortDir: this.table.sortDesc === true ? 'desc' : 'asc'
      };

      if (!this.includeAllOrganizations) {
        params.organizationId = organizationId;
      }

      if (this.hideDeletedUsers === false) {
        params.includeDeleted = true;
      }

      try {
        const users = [];
        const response = await UserServiceV2.listUsers(params);
        this.table.rows = response.pagination.total;

        let allOrgs = [];

        if (this.includeAllOrganizations) {
          allOrgs = await OrganizationService.listAllOrganizations();
        }

        response.data.forEach((user) => {
          if (this.includeAllOrganizations) {
            const userOrg = allOrgs.find((org) => {
              return org.id === user.organizationId;
            });
            user.organization = userOrg?.name || 'None';
          }

          users.push(user);
        });

        return users;
      } catch (error) {
        this.error = error;
        this.handleError('Failed to load user list');
        return [];
      }
    },
    async confirmDelete(row, event) {
      if (event) {
        event.stopPropagation();
      }

      const user = row.item;
      const message = `Are you sure you want to permanently delete ${user.email}?`;
      const confirm = await this.$bvModal.msgBoxConfirm(message);

      if (confirm) {
        this.deleteUser(user);
      }
    },
    async deleteUser(user) {
      this.errorMessage = null;

      try {
        await UserServiceV2.deleteUser(user.id);
        this.onDeleteUser(user);
      } catch (error) {
        const message = error.response?.data?.errors[0]?.message;
        if (message) {
          this.errorMessage = `Unable to delete ${user.email} - ${message}`;
        } else {
          this.errorMessage = `An unknown error occurred while deleting ${user.email}`;
        }
      }
    },
    handleError(text) {
      this.alert.text = text;
      this.alert.model = true;
    },
    tipMethod(rowItem) {
      return (
        this.formatDateTime(this.parseISO(rowItem.lastLogin), {
          format: 'date'
        }) +
        ',' +
        '<br>' +
        this.formatDateTime(this.parseISO(rowItem.lastLogin), {
          format: 'time',
          timeZone: this.sessionUserTimeZone,
          displayTimeZone: true
        })
      );
    }
  }
};
</script>

<style scoped>
.container {
  max-width: 120rem;
  margin-left: 8rem;
}
.container-search {
  padding-bottom: 1rem;
}
.dashline {
  border-bottom: 1px dashed;
}

.button-link {
  color: #212529;
}
</style>

<template>
  <b-form-row class="summary-row">
    <b-col cols="1" />
    <b-col cols="4">
      <b-input-group>
        <template #prepend>
          <b-button variant="light" @click="remove">
            <i class="fa-solid fa-trash"></i>
          </b-button>
        </template>
        <b-form-input
          :name="`input-discount-name`"
          v-model="discount.name"
          :state="validateState(`input-discount-name`)"
          step="any"
          v-validate="{ required: true }"
          :aria-describedby="`input-discount-name-feedback`"
          data-vv-as="name"
        />
      </b-input-group>
    </b-col>
    <b-col cols="4" class="d-flex justify-content-end">
      <b-input-group>
        <template #prepend>
          <b-form-select variant="secondary" v-model="discount.type">
            <b-form-select-option value="amount" selected>$</b-form-select-option>
            <b-form-select-option value="percent">%</b-form-select-option>
          </b-form-select>
        </template>

        <b-form-input
          :name="`input-discount-amount`"
          v-model="discount.amount"
          type="number"
          :state="validateState(`input-discount-amount`)"
          step="any"
          v-validate="{ required: true, min_value: 0 }"
          :aria-describedby="`input-discount-amount-feedback`"
          data-vv-as="amount"
        />
      </b-input-group>
    </b-col>
    <b-col class="border-bottom d-flex justify-content-end align-items-end">
      <div class="text-danger">-{{ formatCurrency(amountCents / 100) }}</div>
    </b-col>
  </b-form-row>
</template>
<script>
export default {
  props: {
    discount: {
      type: Object,
      required: true
    },
    subtotalCents: {
      type: Number,
      required: true
    }
  },
  methods: {
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },

    remove() {
      this.$emit('remove');
    }
  },
  computed: {
    amountCents() {
      let amountCents = 0;

      if (isNaN(parseFloat(this.discount.amount))) return amountCents;

      if (this.discount.type === 'percent') {
        amountCents = Math.round(this.subtotalCents * (parseFloat(this.discount.amount) / 100));
      } else {
        amountCents = parseFloat(this.discount.amount) * 100;
      }

      this.$emit('amountCentsChanged', amountCents);

      return amountCents;
    }
  }
};
</script>

<template>
  <div>
    <b-modal
      :id="'edit-order-' + modalId"
      v-model="modalShow"
      title="Edit Order"
      @show="resetForm"
      @hidden="resetForm"
      @ok="handleOk"
      size="xl"
      no-close-on-backdrop
    >
      <b-overlay :show="isBusy" no-wrap rounded="sm" />
      <b-form @submit.stop.prevent="onSubmit">
        <b-form-row>
          <b-col md="12 mb-4">
            <Alert v-if="errorMessage" variant="red" icon="exclamation" show>
              An issue occured: {{ errorMessage }}
            </Alert>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col class="mb-4">
            <b-row>
              <b-col md="6">
                <b-form-group :invalid-feedback="veeErrors.first('input-first-name')">
                  <label for="input-first-name" class="mb-1">First Name </label>
                  <b-form-input
                    name="input-first-name"
                    v-model="firstName"
                    :state="validateState('input-first-name')"
                    aria-describedby="input-first-name-feedback"
                    data-vv-as="first name"
                    trim
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group :invalid-feedback="veeErrors.first('input-last-name')">
                  <label for="input-last-name" class="mb-1">Last Name</label>
                  <b-form-input
                    name="input-last-name"
                    v-model="lastName"
                    :state="validateState('input-last-name')"
                    aria-describedby="input-last-name-feedback"
                    data-vv-as="last name"
                    trim
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label="Additional name" :invalid-feedback="veeErrors.first('input-secondary-name')">
                  <b-form-input
                    name="input-secondary-name"
                    v-model="secondaryName"
                    :state="validateState('input-secondary-name')"
                    aria-describedby="input-secondary-name-feedback"
                    data-vv-as="additional name"
                    trim
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-form-group
                  label="Address"
                  label-for="input-address"
                  :invalid-feedback="veeErrors.first('input-address')"
                >
                  <b-form-input
                    name="input-address"
                    v-model="address"
                    :state="validateState('input-address')"
                    aria-describedby="input-address-feedback"
                    data-vv-as="address"
                    trim
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="City" label-for="input-city" :invalid-feedback="veeErrors.first('input-city')">
                  <b-form-input
                    name="input-city"
                    v-model="city"
                    :state="validateState('input-city')"
                    aria-describedby="input-city-feedback"
                    data-vv-as="city"
                    trim
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-form-group label-for="province" :invalid-feedback="veeErrors.first('province')">
                  <template v-slot:label>{{ provinceStateView.dropdownLabel }} </template>
                  <b-form-select
                    name="province"
                    v-model="province"
                    :options="provinceStateOptions"
                    :state="validateState('province')"
                    data-vv-as="province"
                  >
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <PostalInput
                  v-if="this.country === 'CA'"
                  ref="postalCode"
                  label="Postal Code"
                  name="postal-code"
                  v-model="postal"
                  placeholder="A#A #A#"
                />
                <ZipInput
                  v-if="this.country === 'US'"
                  ref="zipCode"
                  label="ZIP Code"
                  name="postal-code"
                  v-model="postal"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-form-group label="Phone" label-for="input-phone" :invalid-feedback="veeErrors.first('input-phone')">
                  <b-form-input
                    name="input-phone"
                    v-model="phone"
                    v-validate="{ numeric: true, min: 7, max: 10 }"
                    :state="validateState('input-phone')"
                    aria-describedby="input-phone-feedback"
                    data-vv-as="phone"
                    trim
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Email" label-for="input-email" :invalid-feedback="veeErrors.first('input-email')">
                  <b-form-input
                    name="input-email"
                    v-model="email"
                    v-validate="{ email: true }"
                    :state="validateState('input-email')"
                    aria-describedby="input-email-feedback"
                    data-vv-as="email"
                    trim
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-form-group
                  label="Select a member"
                  label-for="input-event-member"
                  :invalid-feedback="veeErrors.first('input-event-member')"
                >
                  <EventMemberSelectV2
                    v-if="eventId"
                    v-model="selectedEventMember"
                    :eventId="eventId"
                    :currentEventMemberId="currentEventMemberId"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label-for="order-source" :invalid-feedback="veeErrors.first('order-source')">
                  <template v-slot:label>Order Source</template>
                  <b-form-select
                    name="order-source"
                    v-model="selectedOrderSource"
                    :options="optionsOrderSource"
                    :state="validateState('order-source')"
                    data-vv-as="order source"
                  ></b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label="Comment"
                  label-for="input-comments"
                  :invalid-feedback="veeErrors.first('input-comments')"
                >
                  <b-form-textarea
                    id="textarea"
                    v-model="comment"
                    placeholder="Enter comment..."
                    rows="3"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-form-row>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import OrderServiceV2 from '../lib/order-service-v2';
import EventMemberSelectV2 from '@/components/EventMemberSelectV2';
import PostalInput from '@/components/inputs/PostalInput';
import ZipInput from '@/components/inputs/ZipInput';
import ProvinceStateService from '@/lib/province-state-view-service';
import Alert from '@/components/ui/Alert';

const orderSource = [
  { value: '', text: '--- Select a source ---' },
  { value: 'mail', text: 'Mail' },
  { value: 'phone', text: 'Phone' },
  { value: 'walk_in', text: 'Walk in' }
];

export default {
  components: {
    Alert,
    EventMemberSelectV2,
    PostalInput,
    ZipInput
  },
  props: {
    modalId: String,
    orderId: String
  },
  data() {
    return {
      modalShow: false,
      errorMessage: null,
      email: null,
      phone: null,
      firstName: null,
      lastName: null,
      secondaryName: null,
      address: null,
      province: null,
      provinceStateOptions: {},
      provinceStateView: {},
      provinceOptions: null,
      postal: null,
      city: null,
      eventId: null,
      country: null,
      comment: null,
      selectedEventMember: null,
      currentEventMemberId: null,
      selectedOrderSource: null,
      currentCustomerId: null,
      optionsOrderSource: orderSource,
      isBusy: true
    };
  },
  watch: {
    modalShow: async function () {
      await this.getOrder();
      this.isBusy = false;
    }
  },
  methods: {
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },

    resetForm() {
      this.isBusy = true;
      this.errorMessage = null;
      this.email = null;
      this.phone = null;
      this.firstName = null;
      this.lastName = null;
      this.secondaryName = null;
      this.address = null;
      this.province = null;
      this.postal = null;
      this.city = null;
      this.eventId = null;
      this.country = null;
      this.comment = null;
      this.selectedEventMember = null;
      this.currentEventMemberId = null;
      this.selectedOrderSource = null;
      this.selectedCustomer = null;
      this.currentCustomerId = null;

      this.$nextTick(() => {
        this.$validator.reset();
      });
    },

    handleOk(event) {
      event.preventDefault();
      this.onSubmit();
    },

    async getOrder() {
      try {
        const order = await OrderServiceV2.retrieveOrder(this.orderId);

        this.orderId = order.id;
        this.eventId = order.eventId;
        this.firstName = order.firstName;
        this.lastName = order.lastName;
        this.secondaryName = order.secondaryName || null;
        this.email = order.email;
        this.phone = order.phone;
        this.address = order.address;
        this.city = order.city;
        this.province = order.province || null;
        this.postal = order.postal;
        this.country = order.country;
        this.comment = order.info?.comment;
        this.currentEventMemberId = order.eventMemberId || null;
        this.selectedOrderSource = order.info?.source || null;
        this.currentCustomerId = order.customerId || null;

        this.provinceStateView = ProvinceStateService.getDefaultView(this.country);
        this.provinceStateOptions = this.provinceStateView.dropdownOptions;
      } catch (error) {
        this.errorMessage = this.parseError(error).message;
      }
    },

    async onSubmit() {
      // Check for valid fields
      // Only first and last names are required on edit
      const valid = await this.$validator.validateAll();
      const postal = this.country === 'US' ? this.$refs.zipCode.isValid() : this.$refs.postalCode.isValid();

      if (!valid || !postal) {
        return;
      }

      try {
        this.isBusy = true;
        this.errorMessage = null;

        const eventMemberId = this.selectedEventMember ? this.selectedEventMember.id : this.currentEventMemberId;

        const orderUpdateData = {
          // This will allow empty srtings to be sent to the API for the email and postal fields
          // All other purchaser fields will be not be submitted if they are blank and therefore will not update
          purchaser: {
            phone: this.phone ? this.phone : '',
            email: this.email ? this.email : '',
            firstName: this.firstName ? this.firstName : '',
            lastName: this.lastName ? this.lastName : '',
            secondaryName: this.secondaryName ? this.secondaryName : null,
            address: this.address ? this.address : '',
            city: this.city ? this.city : '',
            postal: this.postal ? this.postal.replace(/\s/g, '') : '',
            province: this.province ? this.province : '',
            country: this.country ? this.country : ''
          },
          comment: this.comment === '' ? null : this.comment,
          customerId: this.selectedCustomer?.id || this.currentCustomerId,
          source: this.selectedOrderSource,
          eventMemberId: eventMemberId || null
        };

        await OrderServiceV2.updateOrder(this.orderId, orderUpdateData);

        this.modalShow = false;
        this.$router.go();
      } catch (error) {
        this.errorMessage = this.parseError(error).message;
      } finally {
        this.isBusy = false;
      }
    }
  }
};
</script>

<style scoped></style>

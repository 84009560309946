<template>
  <div>
    <b-modal
      :id="modalId"
      v-model="modalShow"
      title="Delete Invoice"
      @show="onShow"
      @hidden="resetForm"
      @ok="handleOk"
      no-close-on-backdrop
      body-class="position-static"
    >
      <b-overlay :show="loading" no-wrap rounded="sm" />
      <b-form @submit.stop.prevent="onSubmit">
        <div v-if="errorMessage" class="alert alert-danger">{{ errorMessage }}</div>
        <b-form-group
          label="Delete Reason"
          label-for="input-deleted-reason"
          :invalid-feedback="veeErrors.first('input-deleted-reason')"
        >
          <b-form-input
            name="input-deleted-reason"
            v-model="deletedReason"
            :state="validateState('input-deleted-reason')"
            v-validate="{ required: true }"
            aria-describedby="input-deleted-reason-feedback"
            data-vv-as="reason"
          />
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import InvoiceService from '@/lib/invoice-service-v2';

export default {
  props: ['modalId', 'invoice'],
  data() {
    return {
      modalShow: false,
      errorMessage: null,
      deletedReason: null,
      loading: false
    };
  },
  methods: {
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },

    async onShow() {
      this.resetForm();
    },

    resetForm() {
      this.deletedReason = null;
      this.errorMessage = null;

      this.$nextTick(() => {
        this.$validator.reset();
      });
    },

    handleOk(event) {
      event.preventDefault();
      this.onSubmit();
    },

    extractError(error) {
      const message = error.response?.data?.errors?.[0].message;
      if (message) {
        return message;
      }

      return 'An unknown error occurred';
    },

    async onSubmit() {
      const valid = await this.$validator.validateAll();
      if (!valid) {
        return;
      }

      this.loading = true;

      try {
        await InvoiceService.deleteInvoice(this.invoice.id, this.deletedReason);

        this.modalShow = false;
        this.$emit('success');
      } catch (error) {
        this.errorMessage = `Error deleting invoice - ${this.extractError(error)}`;
      }
      this.loading = false;
    }
  }
};
</script>

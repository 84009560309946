<template>
  <div>
    <b-modal
      :id="modalId"
      v-model="modalShow"
      title="Debit Account"
      @show="onShow"
      @hidden="resetForm"
      @ok="handleOk"
      no-close-on-backdrop
      body-class="position-static"
    >
      <b-overlay :show="loading" no-wrap rounded="sm" />
      <p><strong>Organization:</strong> {{ organizationName }}</p>
      <p v-if="event"><strong>Raffle:</strong> {{ event.name }}</p>
      <p><strong>Balance:</strong> {{ formatCurrency(availableBalanceCents / 100) }}</p>
      <b-form @submit.stop.prevent="onSubmit">
        <div v-if="availableBalanceCents < amount * 100" class="alert alert-danger">
          The available balance is less than the amount to debit
        </div>
        <div v-if="errorMessage" class="alert alert-danger">{{ errorMessage }}</div>

        <b-form-group
          label="Amount"
          label-for="input-amount"
          :invalid-feedback="veeErrors.first('input-amount')"
          class="mb-4"
          style="width: 10rem"
        >
          <b-input-group prepend="$" class="mr-3">
            <b-form-input
              name="input-amount"
              v-model="amount"
              type="number"
              :state="validateState('input-amount')"
              step="any"
              v-validate="{ min_value: 0.5, max_value: amountDueCents / 100, required: true }"
              aria-describedby="input-amount-feedback"
              data-vv-as="amount"
            />
          </b-input-group>
        </b-form-group>
        <b-form-group
          label="Description"
          label-for="input-description"
          :invalid-feedback="veeErrors.first('input-description')"
        >
          <b-form-input
            name="input-description"
            v-model="description"
            v-validate="{ max: 22 }"
            max="22"
            :state="validateState('input-description')"
            data-vv-as="description"
            trim
          />
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import OrganizationService from '@/lib/organization-service-v2';
import { getAuth } from '@rafflebox-technologies-inc/auth-service-sdk';

export default {
  name: 'DebitModal',
  props: ['modalId', 'event', 'organizationName', 'organizationId', 'amountDueCents', 'invoiceId', 'fromQboFlow'],
  data() {
    return {
      modalShow: false,
      errorMessage: null,
      amount: this.amountDueCents / 100,
      description: null,
      loading: false,
      accountId: '',
      availableBalanceCents: 0
    };
  },
  methods: {
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },

    async onShow() {
      this.loading = true;
      this.resetForm();
      await this.loadAccountInfo();
      this.loading = false;
    },

    resetForm() {
      this.amount = (this.amountDueCents / 100).toFixed(2);

      if (this.event) {
        this.description = `RB-${this.event.number}`;
      }

      this.errorMessage = null;

      this.$nextTick(() => {
        this.$validator.reset();
      });
    },

    handleOk(event) {
      event.preventDefault();
      this.onSubmit();
    },

    extractError(error) {
      const message = error.response?.data?.errors?.[0].message;
      if (message) {
        return message;
      }

      return 'An unknown error occurred';
    },

    async loadAccountInfo() {
      try {
        /**
         * The following conditional should be removed once the QBO flow is complete
         */
        let id = this.organizationId;

        if (this.fromQboFlow) {
          const sessionUser = await getAuth().sessionUser();
          id = sessionUser.organizationUuid;
        }

        const organization = await OrganizationService.retrieveOrganization(id);
        const accountId = organization.stripeConnectedAccountId;
        const account = await OrganizationService.retrieveAccount(accountId);

        this.accountId = accountId;
        this.availableBalanceCents = account.availableBalanceCents;
      } catch (error) {
        this.errorMessage = `Error loading account details - ${this.extractError(error)}`;
      }
    },

    async onSubmit() {
      const valid = await this.$validator.validateAll();
      if (!valid) {
        return;
      }

      this.loading = true;

      // Debit Account

      try {
        const amountCents = Math.round(this.amount * 100);

        /**
         * The following conditional should be removed once the QBO flow is complete
         */
        if (this.fromQboFlow) {
          await OrganizationService.debitAccountQBO(this.accountId, {
            provider: 'stripe',
            amountCents,
            description: this.description,
            invoiceId: this.invoiceId
          });
        } else {
          await OrganizationService.debitAccount(this.accountId, {
            provider: 'stripe',
            amountCents,
            description: this.description,
            invoiceId: this.invoiceId
          });
        }

        this.modalShow = false;
        this.$emit('success');
      } catch (error) {
        this.errorMessage = `Error debiting account - ${this.extractError(error)}`;
      }
      this.loading = false;
    }
  }
};
</script>

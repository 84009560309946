<template>
  <div>
    <b-modal
      :id="modalId"
      v-model="modalShow"
      title="Move to a different organization"
      @show="resetForm"
      @hidden="resetForm"
      @ok="handleOk"
    >
      <b-form @submit.stop.prevent="onSubmit">
        <div v-if="errorMessage" class="alert alert-danger">
          Failed to move to another organization: {{ errorMessage }}
        </div>
      </b-form>
      <OrganizationSelect
        @organizationSelected="handleOrganizationSelection"
        :currentOrganizationId="raffle.organizationId"
      ></OrganizationSelect>
    </b-modal>
  </div>
</template>

<script>
import EventService from '@/lib/event-service-v2';
import OrganizationSelect from '@/components/OrganizationSelectV2';

export default {
  name: 'MoveRaffleModal',
  components: {
    OrganizationSelect
  },
  props: ['modalId', 'raffle'],
  data() {
    return {
      modalShow: false,
      errorMessage: null,
      selectedOrganization: null
    };
  },
  methods: {
    handleOrganizationSelection(organization) {
      this.selectedOrganization = organization;
    },
    resetForm() {
      this.modalShow = false;
      this.errorMessage = null;

      this.$nextTick(() => {
        this.$validator.reset();
      });
    },
    handleOk(event) {
      event.preventDefault();
      this.onSubmit();
    },
    async onSubmit() {
      const newOrganizationId = this.selectedOrganization.id;

      const eventId = this.raffle.id;

      try {
        await EventService.updateEvent(eventId, { organizationId: newOrganizationId });

        this.modalShow = false;
        this.raffle.organizationId = newOrganizationId;
        this.$emit('onEdited', this.raffle);
      } catch (error) {
        this.errorMessage = this.parseError(error).message;
      }
    }
  }
};
</script>

<style scoped></style>

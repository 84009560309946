import Axios from '@/axios';

export default {
  listDomains: async (params = {}) => {
    const response = await Axios.get(`/domain-service/v2/domains`, {
      params
    });

    return response.data.data;
  }
};

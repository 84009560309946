<template>
  <div>
    <b-alert v-model="success.model" variant="success" dismissible fade>
      {{ success.text }}
    </b-alert>
    <b-alert v-model="alert.model" variant="danger" dismissible fade>
      {{ alert.text }}
    </b-alert>
    <b-row class="container-search">
      <b-col sm="2" v-if="showAdminInvoices">
        <CreateInvoiceModal @invoiceCreated="refreshInvoices" />
      </b-col>
      <b-col sm="4">
        <b-form-input v-model="search" placeholder="Enter search" debounce="300"></b-form-input>
      </b-col>
      <b-col>
        <b-form inline>
          <b-checkbox name="show-paid" v-model="hideTransferred" switch />
          <label for="show-paid">Hide Paid</label>
        </b-form>
      </b-col>
    </b-row>
    <b-table
      show-empty
      id="invoice-table"
      class="invoice-table"
      striped
      sort-icon-left
      :fields="fields"
      :current-page="table.currentPage"
      :items="events"
      :sort-by.sync="table.sortBy"
      :sort-desc.sync="table.sortDesc"
    >
      <template v-slot:table-busy>
        <div class="text-center">
          <LoadingSpinner class="m-auto" />
        </div>
      </template>
      <template v-slot:cell(status)="row">
        <b-badge variant="primary" v-if="row.item.status === 'Due'">Due</b-badge>
        <b-badge variant="success" v-if="row.item.status === 'Paid' || (row.item.status === 'Deposited' && !admin)">
          Paid
        </b-badge>
        <b-badge variant="danger" v-if="row.item.status === 'Overdue'">Overdue</b-badge>
        <b-badge variant="secondary" v-if="admin && row.item.status === 'Deposited'">Deposited</b-badge>
        <i
          class="fa-solid fa-square-check ml-2 text-success"
          :id="`${row.item.id}-popover`"
          v-if="row.item.authorizedAt"
        ></i>
        <i
          class="fa-regular fa-square-check ml-2 text-success"
          :id="`${row.item.id}-popover`"
          v-else-if="row.item.validatedAt"
        ></i>
        <b-popover :target="`${row.item.id}-popover`" triggers="hover" placement="top">
          <template #title>Invoice Authorization</template>
          <div v-if="row.item.validatedAt" class="mb-3">
            <strong class="d-block">Validated</strong>
            <div>{{ formatDateTime(parseISO(row.item.validatedAt)) }}</div>
            <div>{{ row.item.validatedBy.firstName }} {{ row.item.validatedBy.lastName }}</div>
          </div>
          <div v-if="row.item.authorizedAt">
            <strong class="d-block">Authorized</strong>
            <div>{{ formatDateTime(parseISO(row.item.authorizedAt)) }}</div>
            <div>{{ row.item.authorizedBy.firstName }} {{ row.item.authorizedBy.lastName }}</div>
          </div>
        </b-popover>
      </template>
      <template v-slot:cell(invoiceId)="row">
        <div v-if="row.item.settings && row.item.settings.externalUrl">
          <a :href="row.item.settings.externalUrl">{{ row.item.invoiceId }}</a>
        </div>
        <div v-else>
          {{ row.item.invoiceId }}
        </div>
      </template>
      <template #cell(actions)="row">
        <div>
          <ActionMenu>
            <Action icon="eye" @click="row.toggleDetails">Toggle Details</Action>
            <Action icon="pen-to-square" v-b-modal="`edit-${row.item.id}`" v-if="canEditInvoice(row.item)">Edit</Action>
            <Action
              v-b-modal="`debit-${row.item.id}`"
              icon="file-invoice-dollar"
              v-if="row.item.balanceCents > 0 && admin"
            >
              Debit
            </Action>
            <Action v-b-modal="`payout-${row.item.id}`" icon="building-columns"> Deposit </Action>
            <b-dropdown-divider v-if="canDeleteInvoice(row.item)" />
            <Action
              v-b-modal="`delete-${row.item.id}`"
              v-if="canDeleteInvoice(row.item)"
              variant="danger"
              icon="trash-can"
            >
              Delete
            </Action>
          </ActionMenu>
          <DebitModal
            v-if="row.item.balanceCents > 0"
            :modalId="`debit-${row.item.id}`"
            :organizationId="row.item.organization.id"
            :organizationName="row.item.organization.name"
            :event="row.item.event"
            :invoiceId="row.item.id"
            :amountDueCents="row.item.balanceCents"
            @success="refreshInvoices"
          />
          <PayoutModal
            v-if="row.item.status === 'Paid'"
            :modalId="`payout-${row.item.id}`"
            :organizationId="row.item.organization.id"
            :organizationName="row.item.organization.name"
            :countryCode="row.item.country"
            :event="row.item.event"
            :invoiceId="row.item.id"
            :invoiceNumber="row.item.invoiceId"
            :amountDueCents="row.item.originalBalanceCents"
            @success="refreshInvoices"
          />
          <DeleteInvoiceModal
            v-if="admin"
            :modalId="`delete-${row.item.id}`"
            :invoice="row.item"
            @success="refreshInvoices"
          />
          <EditInvoiceModal
            v-if="enableEditInvoice"
            :modalId="`edit-${row.item.id}`"
            :invoiceId="row.item.id"
            @success="refreshInvoices"
          />
        </div>
      </template>
      <template v-slot:row-details="row">
        <ViewInvoice :invoiceId="row.item.id" @paid="invoicePaid" />
      </template>
    </b-table>
    <b-tfoot>
      <b-tr>
        <b-td colspan="7" class="text-right">
          Total Rows: <strong> {{ table.rows }}</strong>
        </b-td>
      </b-tr>
    </b-tfoot>
    <b-pagination
      v-model="table.currentPage"
      :total-rows="table.rows"
      :per-page="table.perPage"
      aria-controls="table"
      pills
      align="center"
      first-text="First"
      prev-text="Prev"
      next-text="Next"
      last-text="Last"
    ></b-pagination>
  </div>
</template>

<script>
import { getAuth } from '@rafflebox-technologies-inc/auth-service-sdk';

import config from '@/config';
import DebitModal from '@/components/DebitModal.vue';
import PayoutModal from '@/components/PayoutModal.vue';
import ActionMenu from '@/components/ActionMenu.vue';
import Action from '@/components/ActionMenuAction.vue';
import CreateInvoiceModal from '@/components/CreateInvoiceModal';
import ViewInvoice from '@/components/ViewInvoice.vue';
import DeleteInvoiceModal from '@/components/modals/DeleteInvoiceModal.vue';
import EditInvoiceModal from '@/components/modals/EditInvoiceModal.vue';
import { unleashFeatureEnabled, UnleashKeys } from '@/lib/unleash';
import LoadingSpinner from '@/components/rbComponents/LoadingSpinner.vue';

import InvoiceService from '@/lib/invoice-service-v2';
import { parseISO } from 'date-fns';

export default {
  name: 'Invoices',
  props: {
    admin: {
      type: Boolean,
      default: false
    }
  },
  components: {
    DebitModal,
    ActionMenu,
    Action,
    PayoutModal,
    CreateInvoiceModal,
    ViewInvoice,
    DeleteInvoiceModal,
    EditInvoiceModal,
    LoadingSpinner
  },
  data() {
    return {
      raffleboxUrl: config.RAFFLEBOX_URL,
      alert: {
        text: '',
        model: false
      },
      success: {
        text: '',
        model: false
      },
      search: '',
      table: {
        isBusy: true,
        rows: 0,
        currentPage: 1,
        perPage: 10,
        sortBy: 'id',
        sortDesc: true
      },
      hideTransferred: true,
      showAdminInvoices: false,
      enableDeleteInvoice: false,
      enableEditInvoice: false
    };
  },
  async mounted() {
    this.showAdminInvoices = await unleashFeatureEnabled(UnleashKeys.AdminInvoices);
    this.enableDeleteInvoice = await unleashFeatureEnabled(UnleashKeys.AccountDeleteInvoice);
    this.enableEditInvoice = await unleashFeatureEnabled(UnleashKeys.AccountEditInvoice);
  },
  computed: {
    sortDir() {
      return this.table.sortDesc === true ? 'desc' : 'asc';
    },

    fields() {
      const organization = {
        key: 'organization.name',
        label: 'Organization'
      };
      const name = {
        key: 'name',
        label: 'Name'
      };
      const number = {
        key: 'event.number',
        label: 'Number'
      };
      const jackpot = {
        key: 'event.jackpotCents',
        label: 'Jackpot',
        formatter: (value) => {
          if (value) {
            return this.formatCurrency(value / 100);
          } else {
            return '';
          }
        }
      };
      const raffleboxFeePercent = {
        key: 'event.raffleboxFeePercent',
        label: 'Fee',
        formatter: (value) => {
          return `${value}%`;
        }
      };
      const amountDueCents = {
        key: 'balanceCents',
        label: 'Due',
        formatter: (value) => {
          return this.formatCurrency(value / 100);
        }
      };
      const status = {
        key: 'status',
        label: 'Status'
      };
      const dueDate = {
        key: 'dueDate',
        label: 'Due Date',
        sortable: true,
        formatter: (value) => {
          if (value) {
            return this.formatDateTime(parseISO(value), { format: 'date' });
          }
          return '';
        }
      };
      const invoiceNumber = {
        key: 'invoiceId',
        label: 'Invoice'
      };
      const actions = {
        key: 'actions',
        label: ''
      };

      if (this.admin) {
        return [
          dueDate,
          organization,
          name,
          number,
          jackpot,
          raffleboxFeePercent,
          amountDueCents,
          status,
          invoiceNumber,
          actions
        ];
      } else {
        return [dueDate, name, number, jackpot, raffleboxFeePercent, amountDueCents, status, invoiceNumber, actions];
      }
    }
  },
  watch: {
    search: function () {
      this.refreshInvoices();
    },
    'table.sortBy': function () {
      this.refreshInvoices();
    },
    hideTransferred: function () {
      this.refreshInvoices();
    }
  },
  methods: {
    refreshInvoices() {
      this.$root.$emit('bv::refresh::table', 'invoice-table');
    },
    handleSuccess(text) {
      this.success.text = text;
      this.success.model = true;
      this.refreshInvoices();
    },
    handleError(error) {
      this.alert.text = error.response ? error.response.data.errors[0].message : error;
      this.alert.model = true;
    },
    invoicePaid() {
      this.refreshInvoices();
    },
    async events() {
      const params = {
        page: this.table.currentPage - 1,
        pageSize: 10,
        sortBy: 'dueDate',
        sortDir: this.sortDir,
        search: this.search ? this.search : undefined
      };

      if (!this.admin) {
        const sessionUser = await getAuth().sessionUser();
        params.organizationId = sessionUser.organizationUuid;
      }

      if (this.hideTransferred) {
        params.hideTransferred = true;
      }

      try {
        const result = await InvoiceService.listInvoices(params);

        this.table.rows = result.pagination.total;
        return result.data;
      } catch (error) {
        this.handleError(error);
        return [];
      }
    },
    onDebit() {
      this.refreshInvoices();
    },
    canDeleteInvoice(invoice) {
      return this.enableDeleteInvoice && this.canEditInvoice(invoice);
    },
    canEditInvoice(invoice) {
      return (
        this.enableEditInvoice &&
        !invoice.validatedAt &&
        !invoice.authorizedAt &&
        invoice.debits.length === 0 &&
        invoice.payouts.length === 0
      );
    }
  }
};
</script>

<style scoped>
.container {
  text-align: left;
  margin-top: 20px;
  margin-left: 115px;
  max-width: 90%;
}
.container-search {
  padding-bottom: 1rem;
}
</style>

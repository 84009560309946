<template>
  <div>
    <LoadingButton v-b-modal.create-organziation-modal variant="outline"> Create Organization </LoadingButton>

    <b-modal
      id="create-organziation-modal"
      title="Create Organization"
      v-model="modalShow"
      @show="resetForm"
      @hidden="resetForm"
      @ok="handleOk"
      no-close-on-backdrop
      size="lg"
    >
      <b-form @submit.stop.prevent="onSubmit">
        <Alert v-if="errorMessage" variant="red" icon="exclamation" show>
          Failed to create organization: {{ errorMessage }}
        </Alert>
        <b-row>
          <b-col sm="12" md="6">
            <b-form-group label-for="input-name" :invalid-feedback="veeErrors.first('input-name')">
              <template slot="label">Organization Name<span class="text-danger"> *</span></template>
              <b-form-input
                name="input-name"
                v-model="name"
                v-validate="{ required: true, min: 3 }"
                :state="validateState('input-name')"
                aria-describedby="input-name-feedback"
                data-vv-as="name"
                trim
              />
            </b-form-group>
          </b-col>
          <b-col sm="12" md="6">
            <b-form-group label-for="input-legal-name" :invalid-feedback="veeErrors.first('input-legal-name')">
              <template slot="label">Organization Legal Name<span class="text-danger"> *</span></template>
              <b-form-input
                name="input-legal-name"
                v-model="legalName"
                v-validate="{ required: true, min: 3 }"
                :state="validateState('input-legal-name')"
                aria-describedby="input-legal-name-feedback"
                data-vv-as="legal name"
                trim
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="6">
            <b-form-group
              label="Description"
              label-for="input-description"
              :invalid-feedback="veeErrors.first('input-description')"
            >
              <b-form-input
                name="input-description"
                v-model="description"
                v-validate="{ min: 3 }"
                :state="validateState('input-description')"
                data-vv-as="description"
                trim
              />
            </b-form-group>
          </b-col>
          <b-col sm="12" md="6">
            <b-form-group label-for="input-shortlink" :invalid-feedback="veeErrors.first('input-shortlink')">
              <template slot="label">Shortlink<span class="text-danger"> *</span></template>
              <b-form-input
                name="input-shortlink"
                v-model="shortlink"
                v-validate="{ required: true, min: 3 }"
                :state="validateState('input-shortlink')"
                data-vv-as="shortlink"
                trim
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="6">
            <b-form-group
              label="Logo URL"
              label-for="input-logo-url"
              :invalid-feedback="veeErrors.first('input-logo-url')"
            >
              <b-form-input
                name="input-logo-url"
                v-model="logoUrl"
                v-validate="{ min: 3 }"
                :state="validateState('input-logo-url')"
                data-vv-as="logo URL"
                trim
              />
            </b-form-group>
          </b-col>
          <b-col sm="12" md="6">
            <b-form-group
              label="Pixel Code"
              label-for="input-pixel-code"
              :invalid-feedback="veeErrors.first('input-pixel-code')"
              class="mb-4"
            >
              <b-form-input
                name="input-pixel-code"
                v-model="pixelCode"
                v-validate="{ min: 3 }"
                :state="validateState('input-pixel-code')"
                data-vv-as="pixel code"
                trim
              />
            </b-form-group>
          </b-col>
        </b-row>
        <CountrySelect v-model="country" required />
        <ProvinceDropdownInput v-if="country" v-model="province" required :country="country" :label="dropdownLabel" />
        <SalesRepDropdownInput v-model="salesRepUuid" required />
        <dl class="d-flex">
          <dd class="text-right mb-4">
            <b-form-checkbox v-model="productGoldrushEnabled" size="lg" name="product-goldrush-enabled" switch />
          </dd>
          <dt class="mt-1">
            Enable Product Goldrush
            <span
              style="margin-left: 0.5rem"
              v-b-tooltip.hover.top="'If enabled then Goldrush will be enabled for this organization.'"
            >
              <i class="fa-solid fa-circle-info"></i>
            </span>
          </dt>
        </dl>
      </b-form>
      <h3 class="text-lg pb-4">Rafflebox Fees</h3>
      <b-row>
        <b-col sm="12" md="6">
          <b-form-group
            label="Online Fee %"
            label-for="input-rafflebox-fee-percent"
            :invalid-feedback="veeErrors.first('input-rafflebox-fee-percent')"
            class="mb-4"
            :state="validateState('input-rafflebox-fee-percent')"
          >
            <b-input-group append="%" class="mr-3">
              <b-form-input
                name="input-rafflebox-fee-percent"
                v-model="raffleboxFeePercent"
                type="number"
                step="any"
                :state="validateState('input-rafflebox-fee-percent')"
                v-validate="{ min_value: 0, max_value: 100, required: true }"
                aria-describedby="input-rafflebox-fee-percent-feedback"
                data-vv-as="Online Fee"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group
            label="In-Venue Fee %"
            label-for="input-rafflebox-fee-percent-in-venue"
            :invalid-feedback="veeErrors.first('input-rafflebox-fee-percent-in-venue')"
            :state="validateState('input-rafflebox-fee-percent-in-venue')"
            class="mb-4"
          >
            <b-input-group append="%" class="mr-3">
              <b-form-input
                name="input-rafflebox-fee-percent-in-venue"
                v-model="raffleboxFeePercentIv"
                type="number"
                step="any"
                :state="validateState('input-rafflebox-fee-percent-in-venue')"
                v-validate="{ min_value: 0, max_value: 100, required: true }"
                aria-describedby="input-rafflebox-fee-percent-in-venue-feedback"
                data-vv-as="In-Venue Fee"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12" md="6">
          <b-form-group
            label="Goldrush Fee %"
            label-for="input-rafflebox-fee-percent-goldrush"
            :invalid-feedback="veeErrors.first('input-rafflebox-fee-percent-goldrush')"
            :state="validateState('input-rafflebox-fee-percent-goldrush')"
            class="mb-4"
          >
            <b-input-group append="%" class="mr-3">
              <b-form-input
                name="input-rafflebox-fee-percent-goldrush"
                v-model="raffleboxFeePercentGoldrush"
                type="number"
                step="any"
                :state="validateState('input-rafflebox-fee-percent-goldrush')"
                v-validate="{ min_value: 0, max_value: 100, required: true }"
                aria-describedby="input-rafflebox-fee-percent-goldrush-feedback"
                data-vv-as="Goldrush Fee"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import store from '@/store';
import OrganizationServiceV2 from '@/lib/organization-service-v2';
import SalesRepDropdownInput from '@/components/inputs/SalesRepDropdownInput';
import ProvinceDropdownInput from '@/components/inputs/ProvinceDropdownInput';
import CountrySelect from '@/components/CountrySelect';
import LoadingButton from '@/components/ui/LoadingButton';
import Alert from '@/components/ui/Alert';

export default {
  components: {
    CountrySelect,
    LoadingButton,
    ProvinceDropdownInput,
    SalesRepDropdownInput,
    Alert
  },
  data() {
    return {
      modalShow: false,
      raffleboxFeePercent: null,
      raffleboxFeePercentIv: null,
      raffleboxFeePercentGoldrush: null,
      errorMessage: null,
      name: null,
      legalName: null,
      description: null,
      shortlink: null,
      salesRepUuid: null,
      logoUrl: null,
      pixelCode: null,
      province: null,
      country: null,
      productGoldrushEnabled: false
    };
  },
  provide() {
    return { parentValidator: this.$validator };
  },
  computed: {
    dropdownLabel() {
      return this.country === 'CA' ? 'Province' : 'State';
    }
  },
  mounted() {
    this.resetForm();
  },
  methods: {
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    resetForm() {
      this.raffleboxFeePercent = null;
      this.raffleboxFeePercentIv = null;
      this.raffleboxFeePercentGoldrush = null;
      this.modalShow = false;
      this.errorMessage = null;
      this.name = null;
      this.legalName = null;
      this.description = null;
      this.shortlink = null;
      this.logoUrl = null;
      this.pixelCode = null;
      this.province = null;
      this.country = null;
      this.salesRepUuid = null;
      this.productGoldrushEnabled = false;
      this.$nextTick(() => {
        this.$validator.reset();
      });
    },
    handleOk(event) {
      event.preventDefault();
      this.onSubmit();
    },
    async onSubmit() {
      const valid = await this.$validator.validateAll();
      if (!valid) {
        return;
      }
      const organization = {
        name: this.name,
        description: this.description,
        shortlink: this.shortlink,
        logo: this.logoUrl,
        pixelCode: this.pixelCode,
        province: this.province,
        country: this.country,
        salesRepId: this.salesRepUuid,
        legalName: this.legalName,
        productGoldrushEnabled: this.productGoldrushEnabled
      };

      organization.raffleboxFeePercent = this.raffleboxFeePercent;
      organization.raffleboxFeePercentIv = this.raffleboxFeePercentIv;
      organization.raffleboxFeePercentGoldrush = this.raffleboxFeePercentGoldrush;
      try {
        const org = await OrganizationServiceV2.createOrganization(organization);
        this.$emit('onCreated', org);
        this.modalShow = false;
        await store.dispatch('CLEAR_ALL_ORGANIZATIONS');
      } catch (error) {
        this.errorMessage = this.parseError(error).message;
      }
    }
  }
};
</script>

<template>
  <div>
    <div class="p-3 w-1/2">
      <OrganizationSelectV2
        inputName="input-organization-select"
        ref="multiselect"
        @organizationSelected="organizationSelected"
      />
    </div>
    <GenericTable
      :columns="columns"
      :data="data"
      :pagination="pagination"
      :loading="loading"
      :actions="!this.admin"
      @sort="sort"
      @updateCurrentPage="updateCurrentPage"
      class="invoices-table"
    >
      <template v-slot:DueDate="slotProps">
        {{ format(new Date(slotProps.row.DueDate), 'yyyy-MM-dd') }}
      </template>
      <template v-slot:Name="slotProps">
        <p>{{ slotProps.row.CustomerRef.name }}</p>
      </template>
      <template v-slot:TotalAmt="slotProps">
        <p class="text-right">
          {{ formatBalance(slotProps.row.TotalAmt) }}
        </p>
      </template>
      <template v-slot:Balance="slotProps">
        <p class="text-right">
          {{ formatBalance(slotProps.row.Balance) }}
        </p>
      </template>
      <template v-slot:Status="slotProps">
        <b-badge variant="danger" v-if="determineStatus(slotProps.row) === 'Overdue'">Overdue</b-badge>
        <b-badge variant="primary" v-else-if="determineStatus(slotProps.row) === 'Due'">Due</b-badge>
        <b-badge variant="success" v-else-if="determineStatus(slotProps.row) === 'Paid'">Paid</b-badge>
      </template>
      <template v-slot:DocNumber="slotProps">
        <p class="mock-link" @click="downloadInvoicePDF(slotProps.row.Id, slotProps.row.DocNumber)">
          {{ slotProps.row.DocNumber }}
        </p>
      </template>
      <template v-slot:actions="slotProps">
        <b-button
          :disabled="slotProps.row.Balance === 0"
          v-b-modal="`debit-${slotProps.row.Id}`"
          :variant="slotProps.row.Balance ? 'primary' : 'success'"
          class="w-26 whitespace-nowrap pt-1 pl-2 pr-2 pb-1"
        >
          {{ slotProps.row.Balance ? 'Pay Balance' : 'Settled' }}
        </b-button>
        <DebitModal
          :modalId="`debit-${slotProps.row.Id}`"
          :organizationId="slotProps.row.CustomerRef.value"
          :organizationName="slotProps.row.CustomerRef.name"
          :invoiceId="slotProps.row.Id"
          :amountDueCents="slotProps.row.Balance * 100"
          :fromQboFlow="true"
          @success="getInvoices"
        />
      </template>
    </GenericTable>
  </div>
</template>

<script>
import GenericTable from '@/components/GenericTable.vue';
import { getAuth } from '@rafflebox-technologies-inc/auth-service-sdk';
import QuickbooksService from '@/lib/quickbooks-service';
import OrganizationSelectV2 from '@/components/OrganizationSelectV2';
import DebitModal from '@/components/DebitModal';
import { format } from 'date-fns';
import { debounce } from 'lodash';

export default {
  components: {
    GenericTable,
    OrganizationSelectV2,
    DebitModal
  },
  props: {
    admin: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      columns: [
        {
          name: 'DueDate',
          label: 'Due Date',
          classes: 'w-auto'
        },
        {
          name: 'Name',
          label: 'Name',
          classes: 'w-auto'
        },
        {
          name: 'TotalAmt',
          label: 'Total',
          classes: 'w-auto text-right'
        },
        {
          name: 'Balance',
          label: 'Balance',
          classes: 'w-auto text-right'
        },
        {
          name: 'Status',
          label: 'Status',
          classes: 'w-auto'
        },
        {
          name: 'DocNumber',
          label: 'Invoice',
          classes: 'w-auto'
        }
      ],
      title: 'Invoices',
      data: [],
      sortBy: 'name',
      sortDir: 'asc',
      pagination: {
        page: 0,
        pageSize: 10,
        total: 0
      },
      loading: false,
      paymentMethod: null,
      format: format,
      organization: null
    };
  },
  async beforeMount() {
    await this.getInvoices();
  },
  watch: {
    organization: debounce(async function () {
      await this.updateCurrentPage(0);
      await this.getInvoices();
    }, 500)
  },
  methods: {
    organizationSelected(organization) {
      this.organization = organization;
    },
    clearOrganization() {
      this.organization = null;
    },
    async getInvoices() {
      this.loading = true;

      const params = {
        pageSize: this.pagination.pageSize,
        pageNumber: this.pagination.page
      };

      const orgId = await this.$store.getters.getOrganization.number;

      params.qboOrganizationId = orgId.toString();

      if (this.admin) {
        params.fetchAll = true;
        if (this.organization) {
          params.qboOrganizationId = this.organization.number;
          delete params.fetchAll;
        }
      }

      try {
        const result = await QuickbooksService.listQuickbookInvoices(params);
        const { invoices, paymentMethod, totalCount } = result;
        this.data = invoices;
        this.pagination.total = totalCount;
        this.loading = false;

        if (!this.admin && paymentMethod) {
          this.paymentMethod = paymentMethod;
        }

        return invoices;
      } catch (error) {
        this.loading = false;
        return [];
      }
    },
    async sort(sortBy, sortDir) {
      this.sortBy = sortBy;
      this.sortDir = sortDir;
      await this.getInvoices();
    },
    selectedRows(rows) {
      this.selectedDevices = rows;
      this.$emit('updateSelectedDevices', rows);
    },
    async updateCurrentPage(page) {
      this.pagination.page = page;
      await this.getInvoices();
    },
    formatBalance(balance) {
      return this.formatCurrency(balance);
    },
    determineStatus(row) {
      const today = new Date();
      const { DueDate, Balance } = row;
      const due = new Date(DueDate);
      if (!Balance) return 'Paid';
      else if (Balance && due.getTime() < today.getTime()) return 'Overdue';
      return 'Due';
    },
    async downloadInvoicePDF(invoiceId, docNumber) {
      const params = {};
      const sessionUser = await getAuth().sessionUser();
      params.qboOrganizationId = sessionUser.organizationId;
      params.invoiceNumber = invoiceId;
      const pdf = await QuickbooksService.getInvoicePDF(params);

      document.activeElement.blur();
      this.downloading = true;

      try {
        const uint = new Uint8Array(pdf.data);
        const blob = new Blob([uint], { type: 'application/pdf' });
        const fileURL = window.URL.createObjectURL(blob);
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', `invoice-${docNumber}.pdf`);
        document.body.appendChild(fileLink);

        fileLink.click();

        document.body.removeChild(fileLink);
        window.URL.revokeObjectURL(fileURL);
      } catch (error) {
        this.$emit('error', error);
      }

      this.downloading = false;
    }
  }
};
</script>

<style>
.mock-link {
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
}
</style>

<template>
  <div>
    <b-modal
      :id="modalId"
      v-model="modalShow"
      title="Edit Tag"
      @show="resetForm"
      @hidden="resetForm"
      @ok="handleOk"
      no-close-on-backdrop
    >
      <b-form @submit.stop.prevent="onSubmit">
        <Alert v-if="errorMessage" variant="red" icon="exclamation">Failed to edit the tag: {{ errorMessage }}</Alert>

        <b-form-group label="Tag Name" label-for="input-tag-name" :invalid-feedback="veeErrors.first('input-tag-name')">
          <b-form-input
            name="input-tag-name"
            v-model="updatedTagName"
            v-validate="{ required: true, min: 3, max: 15, regex: '^[a-zA-Z0-9]+((-|\\s)[a-zA-Z0-9]+)?$' }"
            :state="validateState('input-tag-name')"
            aria-describedby="input-tag-name-feedback"
            data-vv-as="tag name"
            trim
          />
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { getAuth } from '@rafflebox-technologies-inc/auth-service-sdk';

import TagServiceV2 from '@/lib/tag-service-v2';
import Alert from '@/components/ui/Alert';

export default {
  props: ['modalId', 'tag'],
  components: {
    Alert
  },
  data() {
    return {
      modalShow: false,
      errorMessage: null,
      updatedTagName: null
    };
  },
  methods: {
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    resetForm() {
      this.errorMessage = null;
      this.updatedTagName = this.tag.name;

      this.$nextTick(() => {
        this.$validator.reset();
      });
    },

    handleOk(event) {
      event.preventDefault();
      this.onSubmit();
    },

    async onSubmit() {
      const valid = await this.$validator.validateAll();
      if (!valid) {
        return;
      }

      let shouldUpdate = false;

      if (this.updatedTagName !== this.tag.name) {
        shouldUpdate = true;
      }

      try {
        if (shouldUpdate) {
          const sessionUser = await getAuth().sessionUser();
          const organizationId = sessionUser.organizationUuid;
          await TagServiceV2.updateTag(organizationId, this.tag.id, this.updatedTagName);
        }

        this.$emit('onEdited', this.tag);
        this.modalShow = false;
      } catch (error) {
        // TODO: improve error messaging in the api
        this.errorMessage = error.message;
      }
    }
  }
};
</script>

<style scoped></style>

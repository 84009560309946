<template>
  <b-table show-empty class="invoice-details-payouts-table" striped sort-icon-left :fields="fields" :items="payouts">
    <template v-slot:table-busy>
      <div class="text-center">
        <b-spinner class="align-middle"></b-spinner>
      </div>
    </template>
    <template v-slot:cell(userFirstName)="row"> {{ row.item.userFirstName }} {{ row.item.userLastName }} </template>
  </b-table>
</template>
<script>
export default {
  props: ['payouts'],
  data() {
    return {
      fields: [
        {
          key: 'createdAt',
          label: 'Date',
          sortable: false,
          formatter: (value) => {
            return value ? this.formatDateTime(this.parseISO(value), { format: 'date' }) : '';
          }
        },
        {
          key: 'userName',
          label: 'User',
          sortable: false
        },
        {
          key: 'statementDescriptor',
          label: 'Statement Descriptor',
          sortable: false
        },
        {
          key: 'amountCents',
          label: 'Amount',
          sortable: false,
          formatter: (value) => {
            return this.formatCurrency(value / 100);
          }
        }
      ]
    };
  }
};
</script>
<style>
.invoice-details-payouts-table.table-striped tbody tr,
.invoice-details-payouts-table.table-striped tbody tr:hover,
.invoice-details-payouts-table.table-striped tbody td {
  background-color: transparent;
  border: none;
}

.invoice-details-payouts-table.table-striped thead tr,
.invoice-details-payouts-table.table-striped thead tr:hover,
.invoice-details-payouts-table.table-striped thead td {
  background-color: transparent;
  border: none;
}
</style>

<template>
  <div>
    <b-row v-if="invoice.id">
      <b-col>
        <b-card title="Summary">
          <b-row v-if="errorMessage">
            <b-col>
              <Alert variant="red" icon="exclamation"> {{ errorMessage }} </Alert>
            </b-col>
          </b-row>
          <b-row v-for="(item, index) in charges" :key="`invoice-item-${index}`">
            <b-col class="text-right">{{ item.name }}</b-col>
            <b-col cols="3" class="text-right border-bottom">{{ formatCurrency(item.amountCents / 100) }}</b-col>
          </b-row>
          <b-row v-for="(item, index) in discounts" :key="`discounts-${index}`">
            <b-col class="text-right"> {{ item.name }}</b-col>
            <b-col cols="3" class="text-right border-bottom text-danger"
              >-{{ formatCurrency((item.amountCents / 100) * -1) }}</b-col
            >
          </b-row>
          <b-row>
            <b-col class="text-right"><strong>Subtotal</strong></b-col>
            <b-col cols="3" class="text-right border-bottom">{{ formatCurrency(subtotalCents / 100) }}</b-col>
          </b-row>
          <b-row>
            <b-col class="text-right"><strong>Tax</strong></b-col>
            <b-col cols="3" class="text-right border-bottom border-dark">{{ formatCurrency(taxCents / 100) }}</b-col>
          </b-row>

          <b-row>
            <b-col class="text-right"><strong>Total Owed</strong></b-col>
            <b-col cols="3" class="text-right border-bottom">
              {{ formatCurrency(totalCents / 100) }}
            </b-col>
          </b-row>

          <b-row v-for="(payment, index) in invoice.debits" :key="`payment-${index}`">
            <b-col class="text-right">
              Stripe Debit -
              {{ payment.userName }} -
              {{ payment.createdAt ? formatDateTime(parseISO(payment.createdAt), { format: 'date' }) : '' }}
            </b-col>
            <b-col cols="3" class="text-right border-bottom">-{{ formatCurrency(payment.amountCents / 100) }}</b-col>
          </b-row>

          <b-row>
            <b-col class="text-right"><strong>Balance Due</strong></b-col>
            <b-col cols="3" class="text-right border-top border-bottom border-dark">
              <strong :class="balanceDueCents > 0 ? 'text-danger' : ''">
                {{ formatCurrency(balanceDueCents / 100) }}
              </strong>
            </b-col>
          </b-row>

          <div v-if="invoice.validatedAt" class="row mb-2 mt-5">
            <b-col>
              <i class="fa-solid fa-square-check"></i>
              Validated
            </b-col>
            <b-col cols="5">
              {{
                formatDateTime(parseISO(invoice.validatedAt), {
                  format: 'dateTime',
                  timeZone: this.sessionUserTimeZone,
                  displayTimeZone: true
                })
              }}
            </b-col>
            <b-col class="text-align-right">{{ validatedBy }}</b-col>
          </div>
          <div v-if="invoice.authorizedAt" class="row">
            <b-col>
              <i class="fa-solid fa-square-check"></i>
              Authorized
            </b-col>
            <b-col cols="5">
              {{
                formatDateTime(parseISO(invoice.authorizedAt), {
                  format: 'dateTime',
                  timeZone: this.sessionUserTimeZone,
                  displayTimeZone: true
                })
              }}
            </b-col>
            <b-col>{{ authorizedBy }}</b-col>
          </div>

          <div class="d-flex mt-5" v-if="canValidateInvoice || canAuthorizeInvoice">
            <div class="ml-auto mr-2" v-if="canValidateInvoice">
              <b-overlay :show="validating">
                <b-button variant="outline-secondary" @click="validateInvoice" v-if="!invoice.validatedAt">
                  Validate
                </b-button>
              </b-overlay>
            </div>
            <div v-if="canAuthorizeInvoice">
              <b-overlay :show="authorizing">
                <b-button variant="outline-secondary" @click="authorizeInvoice" v-if="!invoice.authorizedAt">
                  Authorize
                </b-button>
              </b-overlay>
            </div>
          </div>
          <div v-if="canPayInvoice && invoice.authorizedAt && invoice.balanceCents > 0" class="d-flex">
            <b-button class="ml-auto" v-b-modal="`invoice-debit-${invoiceId}`" variant="primary" ref="payButton">
              Pay
            </b-button>
            <PayInvoiceModal
              :modalId="`invoice-debit-${invoice.id}`"
              :organizationId="invoice.organization.id"
              :organizationName="invoice.organization.name"
              :event="invoice.event"
              :invoice="invoice"
              :amountDueCents="invoice.balanceCents"
              @success="paymentMade"
            />
          </div>
        </b-card>
      </b-col>
      <b-col>
        <b-card
          title="Rafflebox Stripe Deposits"
          v-if="showRaffleboxPayouts && invoice.payouts && invoice.payouts.length > 0"
        >
          <InvoicePayouts :payouts="invoice.payouts" />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { calculateProvincialSalesTaxCents } from '@rafflebox-technologies-inc/rafflebox-lib';
import parseError from '@/lib/parse-error';

import InvoiceService from '@/lib/invoice-service-v2';

import PayInvoiceModal from '@/components/modals/PayInvoiceModal';
import InvoicePayouts from '@/components/InvoicePayouts';
import { unleashFeatureEnabled, UnleashKeys } from '@/lib/unleash';
import Alert from '@/components/ui/Alert';

export default {
  components: { PayInvoiceModal, InvoicePayouts, Alert },
  props: {
    invoiceId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      invoice: {},
      validating: false,
      authorizing: false,
      errorMessage: null,
      canValidateInvoice: false,
      canAuthorizeInvoice: false,
      canPayInvoice: false,
      showRaffleboxPayouts: false
    };
  },
  async mounted() {
    this.reloadInvoice();
    this.canValidateInvoice = await unleashFeatureEnabled(UnleashKeys.AccountValidateInvoice);
    this.canAuthorizeInvoice = await unleashFeatureEnabled(UnleashKeys.AccountAuthorizeInvoice);
    this.canPayInvoice = await unleashFeatureEnabled(UnleashKeys.AccountPayInvoice);
    this.showRaffleboxPayouts = await unleashFeatureEnabled(UnleashKeys.AccountRaffleboxPayouts);
  },
  computed: {
    charges() {
      return this.invoice.invoiceItems.filter((item) => item.amountCents >= 0);
    },
    discounts() {
      return this.invoice.invoiceItems.filter((item) => item.amountCents < 0);
    },
    subtotalCents() {
      const result = this.invoice.invoiceItems.reduce((total, item) => total + item.amountCents, 0);

      return result;
    },
    taxCents() {
      return calculateProvincialSalesTaxCents(this.invoice.organization.province.toLowerCase(), this.subtotalCents);
    },
    totalCents() {
      return this.subtotalCents + this.taxCents;
    },
    paymentTotalCents() {
      return this.invoice.debits.reduce((total, debit) => total + debit.amountCents, 0);
    },
    balanceDueCents() {
      return this.totalCents - this.paymentTotalCents;
    },
    validatedBy() {
      return `${this.invoice.validatedBy.firstName} ${this.invoice.validatedBy.lastName}`;
    },
    authorizedBy() {
      return `${this.invoice.authorizedBy.firstName} ${this.invoice.authorizedBy.lastName}`;
    }
  },
  methods: {
    async validateInvoice() {
      try {
        this.validating = true;

        await InvoiceService.validateInvoice(this.invoice.id);

        await this.reloadInvoice();
      } catch (error) {
        this.errorMessage = `Failed to validate invoice - ${parseError(error).message}`;
      }

      this.validating = false;
    },
    async authorizeInvoice() {
      try {
        this.authorizing = true;

        await InvoiceService.authorizeInvoice(this.invoice.id);

        await this.reloadInvoice();

        this.$refs.payButton.click();
      } catch (error) {
        this.errorMessage = `Failed to authorize invoice - ${parseError(error).message}`;
      }
      this.authorizing = false;
    },
    async reloadInvoice() {
      const invoice = await InvoiceService.retrieveInvoice(this.invoiceId);

      this.invoice = invoice.data;
    },
    async paymentMade() {
      await this.reloadInvoice();

      this.$emit('paid', this.invoice);
    }
  }
};
</script>

<template>
  <div>
    <CreateTagForm @onCreateTag="onCreateTag" @onError="onError" />
    <TagsTable ref="tagsTable" @onError="onError" />
  </div>
</template>
<script>
import CreateTagForm from '@/components/forms/CreateTagForm';
import TagsTable from '@/components/TagsTable';

export default {
  components: { CreateTagForm, TagsTable },
  data() {
    return {
      tagList: null
    };
  },
  methods: {
    onError(error) {
      this.$emit('onError', error);
    },
    onCreateTag() {
      this.$refs.tagsTable.getTags();
    }
  }
};
</script>

<template>
  <div>
    <b-modal
      :id="'void-order-' + modalId"
      v-model="modalShow"
      :title="title"
      @show="resetForm"
      @hidden="resetForm"
      @ok="handleOk"
    >
      <div v-if="refundInProgress" class="flex"><b-spinner class="mx-auto" /></div>
      <div v-else>
        <div v-if="refundOrderFailed" class="alert alert-danger">Failed to void order: {{ errorMessage }}</div>
        <div v-if="subscriptionId">
          <strong>Are you sure you want to void the subscription?</strong>
        </div>
        <div v-else>
          <strong>Are you sure you want to void order #{{ uuidShort }}?</strong>
        </div>
        <br />
        <b-col>
          <span><strong>Name: </strong>{{ name }}</span>
        </b-col>
        <b-col>
          <span><strong>Email: </strong>{{ email }}</span>
        </b-col>
        <b-col v-if="!subscriptionId">
          <span><strong>Amount: </strong>${{ amountPaid }}</span>
        </b-col>
        <b-col v-if="subscriptionAmount">
          <span><strong>Subscription Amount: </strong>${{ subscriptionAmount.toFixed(2) }}</span>
        </b-col>
        <b-col>
          <span><strong>Date: </strong>{{ formatDateTime(createdAt) }}</span>
        </b-col>
        <br />
      </div>
      <template v-slot:modal-footer="{ cancel }">
        <b-button :disabled="refundInProgress" size="sm" variant="success" @click="refundOrder()"> Confirm </b-button>
        <b-button :disabled="refundInProgress" class="ml-2" size="sm" variant="danger" @click="cancel()">
          Cancel
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import OrderServiceV2 from '@/lib/order-service-v2';

export default {
  props: {
    modalId: {
      type: String
    },
    order: {
      type: Object
    }
  },
  data() {
    return {
      modalShow: false,
      errorMessage: null,
      refundOrderFailed: false,
      refundInProgress: false,

      addressLine1: null,
      amountFee: null,
      amountPaid: null,
      subscriptionAmount: null,
      city: null,
      country: null,
      createdAt: null,
      customerId: null,
      deviceId: null,
      email: null,
      eventId: null,
      eventMemberId: null,
      id: null,
      ipAddress: null,
      name: null,
      numTickets: null,
      paid: null,
      phone: null,
      postal: null,
      referenceId: null,
      referer: null,
      refund: null,
      state: null,
      type: null,
      updatedAt: null,
      userId: null,
      uuid: null,
      uuidShort: null,
      subscriptionId: null
    };
  },

  computed: {
    title() {
      return this.subscriptionId ? 'Void Subscription' : 'Void Order';
    }
  },

  methods: {
    refreshTable() {
      this.$root.$emit('bv::refresh::table', 'table');
    },
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    async getSubscriptionAmount() {
      try {
        return await OrderServiceV2.getSubscriptionAmount(this.order.subscriptionId);
      } catch (error) {
        this.refundOrderFailed = true;
        if (error.response) {
          this.errorMessage = error.response.data.errors[0].message;
        } else {
          this.errorMessage = 'An unexpected error occurred, please try again later.';
        }
      }
    },
    async getActiveSubscriptionOrder() {
      try {
        return await OrderServiceV2.getActiveSubscriptionOrder(this.order.subscriptionId);
      } catch (error) {
        this.refundOrderFailed = true;
        if (error.response) {
          this.errorMessage = error.response.data.errors[0].message;
        } else {
          this.errorMessage = 'An unexpected error occurred, please try again later.';
        }
      }
    },
    async refundOrder() {
      this.refundInProgress = true;

      try {
        if (this.subscriptionId) {
          const activeOrder = await this.getActiveSubscriptionOrder();
          await OrderServiceV2.voidOrder(activeOrder.id);
        } else {
          await OrderServiceV2.voidOrder(this.uuid);
        }

        this.modalShow = false;
        this.refreshTable();

        this.$emit('orderRefunded', {
          uuidShort: this.uuidShort,
          amountPaid: this.amountPaid,
          name: this.name
        });
      } catch (error) {
        this.refundOrderFailed = true;
        this.errorMessage = this.parseError(error).message;
      }

      this.refundInProgress = false;
    },
    async resetForm() {
      this.editCustomerFailed = false;
      this.errorMessage = null;

      this.addressLine1 = this.order.addressLine1;
      this.amountFee = this.order.amountFee;
      this.amountPaid = this.order.amountPaidCents / 100;
      this.subscriptionAmount = this.order.subscriptionId ? await this.getSubscriptionAmount() : null;
      this.city = this.order.city;
      this.country = this.order.country;
      this.createdAt = this.parseISO(this.order.createdAt);
      this.customerId = this.order.customerId;
      this.deviceId = this.order.deviceId;
      this.email = this.order.email;
      this.eventId = this.order.eventId;
      this.eventMemberId = this.order.eventMemberId;
      this.id = this.order.id;
      this.ipAddress = this.order.ipAddress;
      this.name = this.order.name;
      this.numTickets = this.order.numTickets;
      this.paid = this.order.paid;
      this.phone = this.order.phone;
      this.postal = this.order.postal;
      this.referenceId = this.order.referenceId;
      this.referer = this.order.referer;
      this.refund = this.order.refund;
      this.state = this.order.state;
      this.type = this.order.type;
      this.updatedAt = this.order.updatedAt;
      this.userId = this.order.userId;
      this.uuid = this.order.id;
      this.uuidShort = this.formatUuid(this.order.id);
      this.refundOrderFailed = false;
      this.subscriptionId = this.order.subscriptionId;

      this.$nextTick(() => {
        this.$validator.reset();
      });
    },

    handleOk(event) {
      event.preventDefault();
      this.onSubmit();
    }
  }
};
</script>

const raffleboxPercentFee = (context) => {
  const data = {
    jackpotCents: 0,
    raffleboxFeePercent: 0,
    ...context
  };

  return data.jackpotCents * (data.raffleboxFeePercent / 100);
};

const raffleboxOrderFee = (context) => {
  const data = {
    orderCount: 0,
    raffleboxOrderFeeCents: 0,
    ...context
  };

  return data.orderCount * data.raffleboxOrderFeeCents;
};

export const invoiceCodeCalculators = {
  raffleboxPercentFee,
  raffleboxOrderFee
};

import Axios from '@/axios';

export default {
  createTag: async (organizationId, name) => {
    const response = await Axios.post('/tag-service/v2/tags', {
      organizationId,
      name,
      system: true
    });

    return response.data;
  },

  /**
   * Adds a tag to an organization.
   */
  addOrganizationTag: async (organizationId, tagId) => {
    const response = await Axios.post('/tag-service/v2/tags/add', {
      organizationId,
      tagId
    });

    return response.data;
  },

  /**
   * Adds a tag to an cudtomer.
   */
  addCustomerTag: async (organizationId, customerId, tagId) => {
    const response = await Axios.post('/tag-service/v2/tags/add', {
      organizationId,
      customerId,
      tagId
    });

    return response.data;
  },
  /**
   * Removes a tag from an organization.
   */
  removeOrganizationTag: async (organizationId, name) => {
    await Axios.delete(`/tag-service/v2/tags/remove`, {
      data: {
        organizationId,
        name
      }
    });
  },

  /**
   * Removes a tag from a customer.
   */
  removeCustomerTag: async (organizationId, customerId, name) => {
    await Axios.delete(`/tag-service/v2/tags/remove`, {
      data: {
        organizationId,
        customerId,
        name
      }
    });
  },

  updateTag: async (organizationId, tagId, name) => {
    const response = await Axios.patch(`/tag-service/v2/tags/${tagId}`, {
      organizationId,
      name,
      system: true
    });

    return response.data;
  },

  listTags: async (params = {}) => {
    const response = await Axios.get('/tag-service/v2/tags', {
      params
    });

    return response.data.data;
  },

  /**
   * Adds a tag to an event.
   * @param {string} organizationId
   * @param {string} eventId
   * @param {string} tagId
   */

  addEventTag: async (organizationId, eventId, tagId) => {
    const response = await Axios.post('/tag-service/v2/tags/add', {
      organizationId,
      eventId,
      tagId
    });

    return response.data;
  },

  /**
   * Removes a tag from an event.
   * @param {string} organizationId
   * @param {string} eventId
   * @param {string} name
   */

  removeEventTag: async (organizationId, eventId, name) => {
    await Axios.delete(`/tag-service/v2/tags/remove`, {
      data: {
        organizationId,
        eventId,
        name
      }
    });
  }
};

<template>
  <div class="flex flex-wrap xl:flex-nowrap justify-start items-start search-wrap">
    <div class="mb-2 before-search" v-if="hasBeforeSlot">
      <slot name="beforeSearch"></slot>
    </div>
    <div class="relative max-w-lg mb-2 pr-2 search">
      <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
        <font-awesome-icon class="h-5 w-5 text-gray-400 text-xl" :icon="['far', 'magnifying-glass']" />
      </div>
      <input
        v-model="search"
        class="block w-full rounded-md border h-11 pl-10"
        type="search"
        placeholder="Enter search"
      />
    </div>
    <div class="mb-2 after-search" v-if="hasAfterSlot">
      <slot name="afterSearch"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchHeader',
  data() {
    return {
      search: null
    };
  },
  computed: {
    hasBeforeSlot() {
      return !!this.$slots.beforeSearch;
    },

    hasAfterSlot() {
      return !!this.$slots.afterSearch;
    }
  }
};
</script>

<style>
.search-wrap {
  padding-bottom: 1rem;
}

.search {
  width: 100%;
}

/* clears the ‘X’ from Internet Explorer */
input[type='search']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
/* clears the ‘X’ from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}
</style>

import Axios from '@/axios';

const baseRoute = '/invoice-service/v2';

export default {
  retrieveInvoice: async (id) => {
    const response = await Axios.get(`${baseRoute}/invoices/${id}`);

    return response.data.data;
  },

  updateInvoice: async (id, body) => {
    const response = await Axios.patch(`${baseRoute}/invoices/${id}`, body);

    return response.data.data;
  },

  createInvoice: async (body) => {
    const response = await Axios.post(`${baseRoute}/invoices`, body);

    return response.data.data;
  },

  listInvoices: async (params = {}) => {
    const response = await Axios.get(`${baseRoute}/invoices`, {
      params
    });

    return response.data.data;
  },

  validateInvoice: async (invoiceId) => {
    const response = await Axios.post(`${baseRoute}/invoices/${invoiceId}/validate`);

    return response.status === 204;
  },

  authorizeInvoice: async (invoiceId) => {
    const response = await Axios.post(`${baseRoute}/invoices/${invoiceId}/authorize`);

    return response.status === 204;
  },

  deleteInvoice: async (invoiceId, deletedReason) => {
    const response = await Axios.delete(`${baseRoute}/invoices/${invoiceId}`, { data: { deletedReason } });

    return response.status === 204;
  }
};

<template>
  <div class="max-width">
    <GenericTable
      :loading="loading"
      :columns="columns"
      :data="tags"
      :pagination="pagination"
      :actions="true"
      @updateCurrentPage="pageUpdate"
      @sort="sort"
      class="tags"
    >
      <template #createdAt="{ row }">
        {{ row.createdAt ? formatDateTime(parseISO(row.createdAt)) : '' }}
      </template>
      <template #actions="{ row }">
        <span v-b-modal="row.id"><i class="fa-regular fa-pen-to-square"></i></span>
        <EditTagModal @onEdited="onEditTag" :modalId="row.id" :tag="row" />
      </template>
    </GenericTable>
  </div>
</template>

<script>
import EditTagModal from '@/components/EditTagModal';
import GenericTable from '@/components/GenericTable';
import TagServiceV2 from '@/lib/tag-service-v2';

export default {
  components: {
    EditTagModal,
    GenericTable
  },
  props: ['filter'],
  data() {
    return {
      loading: false,
      tags: [],
      pagination: {
        total: 0,
        page: 0,
        pageSize: 20,
        sortDir: 'asc',
        sortBy: 'name'
      },
      currentPage: 0,
      columns: [
        {
          name: 'name',
          label: 'Name',
          sortable: true,
          classes: 'w-auto'
        },
        {
          name: 'createdAt',
          label: 'Created At',
          sortable: true,
          classes: 'w-28 text-center'
        }
      ]
    };
  },
  mounted() {
    this.getTags();
  },
  methods: {
    onEditTag() {
      this.getTags();
    },
    sort(sortBy, sortDir) {
      this.$emit('sorting', true);
      this.pagination.sortBy = sortBy;
      this.pagination.sortDir = sortDir;
      this.getTags();
    },
    pageUpdate(page) {
      this.pagination.page = page;
      this.getTags();
    },
    async getTags() {
      this.loading = true;
      try {
        const params = {
          system: true,
          page: this.pagination.page,
          pageSize: this.pagination.pageSize,
          sortDir: this.pagination.sortDir === 'desc' ? 'desc' : 'asc',
          sortBy: this.pagination.sortBy
        };

        const tags = await TagServiceV2.listTags(params);

        const returnedTags = [];

        for (const tag of tags.data) {
          returnedTags.push({
            id: tag.id,
            name: tag.name,
            createdAt: tag.createdAt
          });
        }

        this.pagination = { ...tags.pagination };

        this.tags = returnedTags;
      } catch (error) {
        this.$emit('onError', error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style>
.max-width {
  max-width: 1460px;
}
</style>

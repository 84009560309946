<template>
  <div>
    <b-button v-b-modal.invite-user-modal variant="outline-secondary"> Invite User </b-button>

    <b-modal
      id="invite-user-modal"
      title="Invite User"
      v-model="modalShow"
      @show="resetForm"
      @hidden="resetForm"
      @ok="handleOk"
      no-close-on-backdrop
    >
      <b-form @submit.stop.prevent="onSubmit">
        <div v-if="inviteUserFailed" class="alert alert-danger">Failed to invite user: {{ errorMessage }}</div>

        <b-form-group label="Organization">
          <OrganizationSelect v-if="isRbAdmin" v-model="organizationId" inputName="input-organization" />
        </b-form-group>

        <UserRolesInput v-model="roles" @onRoleChange="updateRoles" :email="email" />

        <b-form-group label="Email" label-for="input-email" :invalid-feedback="veeErrors.first('input-email')">
          <b-form-input
            name="input-email"
            v-model="email"
            v-validate="{ required: true, email: true }"
            :state="validateState('input-email')"
            data-vv-as="email"
            trim
          />
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { getAuth, Roles } from '@rafflebox-technologies-inc/auth-service-sdk';
import UserServiceV2 from '@/lib/user-service-v2';
import OrganizationSelect from './OrganizationSelectV2.vue';
import UserRolesInput from '@/components/forms/UserRolesInput.vue';
export default {
  components: { OrganizationSelect, UserRolesInput },
  data() {
    return {
      modalShow: false,
      inviteUserFailed: false,
      errorMessage: null,
      roles: [],
      email: null,
      organizationId: null,
      isRbAdmin: false
    };
  },
  methods: {
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    updateRoles(selectedRoles) {
      this.roles = selectedRoles;
    },
    resetForm() {
      this.modalShow = false;
      this.inviteUserFailed = false;
      this.errorMessage = null;
      this.roles = [];
      this.email = null;
      this.organizationId = undefined;
      this.$nextTick(() => {
        this.$validator.reset();
      });
    },
    handleOk(event) {
      event.preventDefault();
      this.onSubmit();
    },
    async onSubmit() {
      const valid = await this.$validator.validateAll();
      if (!valid) {
        return;
      }
      try {
        const invite = {
          email: this.email,
          roles: this.roles,
          organizationId: this.organizationId
        };
        const newUser = await UserServiceV2.inviteUser(invite);
        this.$emit('onInvited', newUser);
        this.modalShow = false;
      } catch (error) {
        this.inviteUserFailed = true;
        if (error.response) {
          this.errorMessage = error.response.data.errors[0].message;
        } else {
          this.errorMessage = 'An unexpected error occurred, please try again later.';
        }
      }
    }
  },
  created: async function () {
    const sessionUser = await getAuth().sessionUser();
    // Render of `<template />` is sync. Therefore we save the role in the component’s data, then render that data.
    this.isRbAdmin = sessionUser.hasRole(Roles.RB_ADMIN);
  }
};
</script>

<style scoped></style>

<template>
  <b-row>
    <b-col cols="4">
      <b-card header="Details">
        <b-form @submit.stop.prevent="onSubmit">
          <div v-if="errorMessage" class="alert alert-danger">{{ errorMessage }}</div>

          <b-form-group
            label="Organization"
            label-for="input-organization"
            :invalid-feedback="veeErrors.first('input-organization')"
          >
            <OrganizationSelect
              v-model="invoice.organizationId"
              inputName="input-organization"
              :defaultOption="invoice.organization"
              @organizationSelected="organizationSelected"
            />
          </b-form-group>

          <div v-if="invoice.organizationId">
            <b-form-group label="Raffle" label-for="input-raffle" :invalid-feedback="veeErrors.first('input-name')">
              <EventSelectV2
                v-model="invoice.eventId"
                :organizationId="invoice.organizationId"
                :defaultOption="invoice.event"
                inputName="input-raffle"
                @eventSelected="eventSelected"
              />
            </b-form-group>

            <b-form-group label="Name" label-for="input-name" :invalid-feedback="veeErrors.first('input-name')">
              <b-form-input
                name="input-name"
                v-model="invoice.name"
                v-validate="{ required: true, min: 3 }"
                :state="validateState('input-name')"
                aria-describedby="input-name-feedback"
                data-vv-as="name"
                trim
              />
            </b-form-group>

            <b-form-group
              label="Due Date"
              label-for="input-due-date"
              :invalid-feedback="veeErrors.first('input-due-date')"
            >
              <b-form-datepicker
                name="input-due-date"
                v-model="invoice.dueDate"
                :min="new Date()"
                v-validate="{
                  required: true
                }"
                :state="validateState('input-due-date')"
                aria-describedby="input-due-date-feedback"
                data-vv-as="due date"
              />
            </b-form-group>

            <b-form-group
              label-for="input-due-time"
              :invalid-feedback="veeErrors.first('input-due-time')"
              label-size="sm"
              label-class="mb-0"
              :description="timeZone"
            >
              <b-form-timepicker
                name="input-due-time"
                v-model="invoice.dueTime"
                v-validate="{ required: true }"
                :state="validateState('input-due-time')"
                aria-describedby="input-due-time-feedback"
                data-vv-as="due time"
                size="sm"
              />
            </b-form-group>
            <b-form-group
              label="Invoice Number"
              label-for="input-invoice-id"
              :invalid-feedback="veeErrors.first('input-invoice-id')"
            >
              <b-form-input
                name="input-invoice-id"
                v-model="invoice.invoiceId"
                v-validate="{ required: true }"
                :state="validateState('input-invoice-id')"
                aria-describedby="input-invoice-id-feedback"
                data-vv-as="invoice ID"
                trim
              />
            </b-form-group>
            <b-form-group
              label="Invoice Link"
              label-for="input-invoice-link"
              :invalid-feedback="veeErrors.first('input-invoice-link')"
            >
              <b-form-input
                name="input-invoice-link"
                v-model="invoice.settings.externalUrl"
                v-validate="{ required: true }"
                :state="validateState('input-invoice-link')"
                aria-describedby="input-invoice-link-feedback"
                data-vv-as="invoice link"
                trim
              />
            </b-form-group>
          </div>
        </b-form>
      </b-card>
    </b-col>
    <b-col>
      <b-card header="Invoice" v-if="invoice.organizationId">
        <b-row v-if="invoice.event" class="mb-3">
          <b-col cols="4"> Raffle Jackpot </b-col>
          <b-col>
            {{ formatCurrency(invoice.event.jackpotCents / 100) }}
          </b-col>
        </b-row>
        <hr />
        <b-form>
          <div class="d-flex">
            <div class="name-column">Name</div>
            <div class="amount-column">Amount</div>
          </div>
          <div class="d-flex mt-2" v-for="(invoiceItem, index) in invoice.invoiceItems" :key="`item-${index}`">
            <b-input-group class="name-column">
              <b-form-input
                :name="`input-name-${index}`"
                v-model="invoiceItem.name"
                :state="validateState(`input-name-${index}`)"
                step="any"
                v-validate="{ required: true }"
                :aria-describedby="`input-name-${index}-feedback`"
                data-vv-as="name"
              />
            </b-input-group>
            <b-input-group prepend="$" class="amount-column">
              <b-form-input
                :name="`input-amount-${index}`"
                v-model="invoiceItem.amount"
                type="number"
                :state="validateState(`input-amount-${index}`)"
                step="any"
                v-validate="{ required: true }"
                :aria-describedby="`input-amount-${index}-feedback`"
                data-vv-as="amount"
              />
            </b-input-group>
            <b-button class="remove-column" variant="light" @click="removeItem(invoiceItem)">
              <i class="fa-solid fa-trash"></i>
            </b-button>
          </div>
          <div class="mt-2 d-flex justify-content-end">
            <b-select
              :options="
                invoiceItemCodes.map((item) => {
                  return { text: item.text, value: item };
                })
              "
              v-model="addItemCode"
              class="w-25"
            />
            <b-button variant="success" @click="addItemClicked">Add</b-button>
          </div>
          <b-form-row class="mt-3 summary-row">
            <b-col cols="6" />
            <b-col>
              <div class="text-right">Total Charges:</div>
            </b-col>
            <b-col class="border-bottom">
              <div class="text-right">{{ formatCurrency(subtotalCents / 100) }}</div>
            </b-col>
          </b-form-row>

          <InvoiceDiscount
            v-for="(discount, index) in invoice.discounts"
            :key="`discount-${index}`"
            :discount="discount"
            @remove="removeDiscount(discount)"
            @amountCentsChanged="discount.amountCents = $event"
            :subtotalCents="subtotalCents"
          />

          <b-form-row class="summary-row mb-2">
            <b-col cols="6" />
            <b-col class="text-right">
              <b-link @click="addDiscount">add discount</b-link>
            </b-col>
            <b-col />
          </b-form-row>
          <b-form-row v-if="discountCents > 0" class="summary-row">
            <b-col cols="6" />
            <b-col>
              <div class="text-right">Subtotal:</div>
            </b-col>
            <b-col class="border-bottom subtotal-after-discount">
              <div class="text-right">{{ formatCurrency(subtotalAfterDiscountCents / 100) }}</div>
            </b-col>
          </b-form-row>
          <b-form-row class="summary-row" v-for="tax in taxDetails" :key="tax.label">
            <b-col cols="6" />
            <b-col>
              <div class="text-right">Tax ({{ tax.label }} {{ tax.rate }}%):</div>
            </b-col>
            <b-col class="border-bottom">
              <div class="text-right">{{ formatCurrency(tax.amountCents / 100) }}</div>
            </b-col>
          </b-form-row>
          <b-form-row class="summary-row">
            <b-col cols="6" />
            <b-col>
              <div class="text-right"><strong>Total:</strong></div>
            </b-col>
            <b-col class="border-bottom border-dark">
              <div class="text-right">{{ formatCurrency(totalCents / 100) }}</div>
            </b-col>
          </b-form-row>
        </b-form>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import { format } from 'date-fns-tz';
import { parseISO, addDays } from 'date-fns';

import {
  calculateProvincialSalesTaxCents,
  getTaxRate,
  calculateTax,
  TaxRateNotFoundError
} from '@rafflebox-technologies-inc/rafflebox-lib';
import { getTimezone } from '@rafflebox-technologies-inc/rafflebox-lib';

import { invoiceCodeCalculators } from '@/lib/invoice-calculator';

import EventSelectV2 from '@/components/EventSelectV2';
import OrganizationSelect from '@/components/OrganizationSelectV2';
import InvoiceDiscount from '@/components/forms/InvoiceDiscount';

import OrderServiceV2 from '@/lib/order-service-v2';

const rbPercentFee = { text: 'Rafflebox Percent Fee', code: 'raffleboxPercentFee' };
const rbTxFee = { text: 'Rafflebox Order Fee', code: 'raffleboxOrderFee' };
const other = { text: 'Other', code: '' };

export default {
  props: ['invoice'],
  components: {
    EventSelectV2,
    OrganizationSelect,
    InvoiceDiscount
  },
  data() {
    return {
      modalShow: false,
      errorMessage: null,
      creating: false,
      addItemCode: other,
      orderCount: 0
    };
  },
  computed: {
    timeZone() {
      if (this.invoice.event) {
        return getTimezone(this.invoice.event.province);
      } else if (this.invoice.organization) {
        return getTimezone(this.invoice.organization.province);
      } else {
        return 'UTC';
      }
    },
    taxDetails() {
      if (!this.invoice.organization || !this.invoice.organization.province) {
        return [];
      }

      try {
        const rates = getTaxRate(this.invoice.organization.province);
        const taxes = calculateTax(this.invoice.organization.province, this.subtotalAfterDiscountCents);

        const details = [];

        if (taxes.pstCents) {
          details.push({ label: 'PST', rate: rates.pst, amountCents: taxes.pstCents });
        }

        if (taxes.gstCents) {
          details.push({ label: 'GST', rate: rates.gst, amountCents: taxes.gstCents });
        }

        if (taxes.hstCents) {
          details.push({ label: 'HST', rate: rates.hst, amountCents: taxes.hstCents });
        }

        return details;
      } catch (error) {
        if (error instanceof TaxRateNotFoundError) {
          return [];
        } else {
          throw error;
        }
      }
    },

    totalTaxCents() {
      try {
        return calculateProvincialSalesTaxCents(
          this.invoice.organization.province.toLowerCase(),
          this.subtotalAfterDiscountCents
        );
      } catch (error) {
        if (error instanceof TaxRateNotFoundError) {
          return 0;
        }

        throw error;
      }
    },

    subtotalCents() {
      const totalCents = this.invoice.invoiceItems.reduce((total, item) => {
        if (isNaN(parseFloat(item.amount))) return total;

        return total + parseFloat(item.amount) * 100;
      }, 0);

      return totalCents;
    },

    discountCents() {
      const totalCents = this.invoice.discounts.reduce((total, discount) => {
        return total + discount.amountCents;
      }, 0);

      return totalCents;
    },

    subtotalAfterDiscountCents() {
      return this.subtotalCents - this.discountCents;
    },

    totalCents() {
      return this.subtotalCents - this.discountCents + this.totalTaxCents;
    },
    invoiceItemCodes() {
      const options = [other];

      if (this.eventId) {
        if (this.invoice.event.raffleboxFeePercent) {
          options.push(rbPercentFee);
        }

        if (this.invoice.event.raffleboxOrderFeeCents) {
          options.push(rbTxFee);
        }
      }

      return options;
    }
  },
  methods: {
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },

    addItemClicked() {
      if (this.addItemCode) {
        this.addItem(this.addItemCode.code, this.addItemCode.type, this.addItemCode.text);
      } else {
        this.addItem();
      }
    },

    addItem(code = '', type = '', name = '') {
      let amountCents = 0;

      const calc = invoiceCodeCalculators[code];

      if (calc) {
        amountCents = calc({
          province: this.invoice.organization.province,
          raffleboxFeePercent: this.invoice.event.raffleboxFeePercent,
          raffleboxOrderFeeCents: this.invoice.event.raffleboxOrderFeeCents,
          orderCount: this.orderCount,
          jackpotCents: this.invoice.event?.jackpotCents || 0
        });
      }

      this.invoice.invoiceItems.push({
        type,
        name,
        amount: (amountCents / 100).toFixed(2)
      });
    },

    addDiscount() {
      this.invoice.discounts.push({
        name: '',
        type: 'amount',
        amount: '',
        amountCents: null
      });
    },

    removeItem(item) {
      const index = this.invoice.invoiceItems.indexOf(item);

      if (index > -1) {
        this.invoice.invoiceItems.splice(index, 1);
      }
    },

    removeDiscount(discount) {
      const index = this.invoice.discounts.indexOf(discount);

      if (index > -1) {
        this.invoice.discounts.splice(index, 1);
      }
    },

    async resetForm() {
      this.invoice.organizationId = null;
      this.errorMessage = null;

      this.organizationSelected();

      this.$nextTick(() => {
        this.$validator.reset();
      });
    },

    organizationSelected(organization) {
      this.invoice.name = null;
      this.invoice.dueDate = null;
      this.invoice.dueTime = null;
      this.invoice.invoiceId = null;
      this.invoice.settings = {
        externalUrl: null
      };

      if (organization) {
        this.invoice.organization = organization;
      }

      this.invoice.event = null;
      this.invoice.eventId = null;
    },

    async eventSelected(event) {
      this.addItemCode = other;

      this.invoice.invoiceItems = [];
      this.invoice.discounts = [];
      this.orderCount = 0;

      if (event) {
        this.invoice.event = event;
        this.invoice.name = event.name;

        const endDate = parseISO(event.endDate);
        const dueDate = addDays(endDate, 30);

        this.invoice.dueDate = format(dueDate, 'yyyy-MM-dd', { timeZone: this.timeZone });
        this.invoice.dueTime = format(dueDate, 'HH:mm', { timeZone: this.timeZone });

        if (this.invoice.event.raffleboxOrderFeeCents) {
          this.orderCount = await this.getEventOrderCount(event);

          this.addItem(
            'raffleboxOrderFee',
            'Rafflebox Fee',
            `Rafflebox Fee - ${this.formatCurrency(this.invoice.event.raffleboxOrderFeeCents / 100)} per order (${
              this.orderCount
            } orders)`
          );
        } else {
          this.addItem('raffleboxPercentFee', 'Rafflebox Fee', `Rafflebox Fee - ${event.raffleboxFeePercent}%`);
        }
      }
    },

    async getEventOrderCount(event) {
      const result = await OrderServiceV2.listOrders({
        eventId: event.id,
        pageSize: 1,
        status: 'ACTIVE'
      });

      return result.pagination.total;
    }
  }
};
</script>

<style scoped>
.type-column {
  width: 20%;
  margin-right: 8px;
}
.name-column {
  width: 65%;
  margin-right: 8px;
}
.amount-column {
  width: 35%;
  margin-right: 8px;
}
.remove-column {
  width: 10%;
}
</style>

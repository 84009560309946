<template>
  <b-container class="mb-7">
    <Alert v-if="successMessage" variant="green" icon="check"> {{ successMessage }} </Alert>
    <Alert v-if="errorMessage" variant="red" icon="exclamation">
      {{ errorMessage }}
    </Alert>

    <b-tabs pills vertical content-class="responsive" v-model="currentTab">
      <b-tab title="Organizations" :title-item-class="showAdminOrganizations ? 'd-block' : 'd-none'">
        <b-row>
          <b-col>
            <h1>
              <span class="text-muted">Admin ></span>
              Organizations
            </h1>
          </b-col>
        </b-row>
        <OrganizationsTable />
      </b-tab>
      <b-tab title="Raffles" lazy :title-item-class="showAdminRaffles ? 'd-block' : 'd-none'">
        <b-row>
          <b-col>
            <h1>
              <span class="text-muted">Admin ></span>
              Raffles
            </h1>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <AdminRaffleList @sorting="handleSort" />
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="Orders" lazy :title-item-class="showAdminOrders ? 'd-block' : 'd-none'">
        <b-row>
          <b-col>
            <h1>
              <span class="text-muted">Admin ></span>
              Orders
            </h1>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <Orders :isRbAdmin="true" class="orders-page" />
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="Users" lazy :title-item-class="showAdminUsers ? 'd-block' : 'd-none'">
        <b-row>
          <b-col>
            <h1>
              <span class="text-muted">Admin ></span>
              Users
            </h1>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <UserList :includeAllOrganizations="true" />
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="Devices" lazy :title-item-class="showAdminDevices ? 'd-block' : 'd-none'">
        <b-row>
          <b-col>
            <h1>
              <span class="text-muted">Admin ></span>
              Devices
            </h1>
          </b-col>
        </b-row>
        <DeviceList @sorting="handleSort" @error="handleError" />
      </b-tab>

      <b-tab title="Invoices" lazy :title-item-class="showAdminInvoices ? 'd-block' : 'd-none'">
        <b-row>
          <b-col>
            <h1>
              <span class="text-muted">Admin ></span>
              Invoices
            </h1>
          </b-col>
        </b-row>
        <Invoices :admin="true" />
      </b-tab>
      <b-tab title="Invoices (New)" lazy :title-item-class="showQuickbooksInvoices ? 'd-block' : 'd-none'">
        <b-row>
          <b-col>
            <h1>
              <span class="text-muted">Admin ></span>
              Invoices
            </h1>
          </b-col>
        </b-row>
        <InvoicesQBO :admin="true" />
      </b-tab>
      <b-tab title="Domains" lazy :title-item-class="showManageDomains ? 'd-block' : 'd-none'">
        <b-row>
          <b-col>
            <h1>
              <span class="text-muted">Admin ></span>
              Domains
            </h1>
          </b-col>
        </b-row>
        <AdminDomainsList />
      </b-tab>
      <b-tab title="Tags" lazy>
        <b-row>
          <b-col>
            <h1>
              <span class="text-muted">Admin ></span>
              Tags
            </h1>
          </b-col>
        </b-row>
        <Tags @sorting="handleSort" @onError="handleError" />
      </b-tab>
      <!--Players Tab-->
      <b-tab title="Players" lazy :title-item-class="showPlayers ? 'd-block' : 'd-none'">
        <b-row>
          <b-col>
            <h1>
              <span class="text-muted">Admin ></span>
              Players
            </h1>
          </b-col>
        </b-row>
        <Players />
      </b-tab>
    </b-tabs>
  </b-container>
</template>

<script>
import { getAuth, Roles } from '@rafflebox-technologies-inc/auth-service-sdk';

import AdminRaffleList from '@/components/AdminRaffleList.vue';
import Invoices from '@/components/Invoices.vue';
import InvoicesQBO from '@/components/InvoicesQBO.vue';
import Tags from '@/pages/admin/Tags';
import Orders from '@/pages/Orders';
import OrganizationsTable from '@/components/OrganizationsTable.vue';
import UserList from '@/components/UserList.vue';
import DeviceList from '@/pages/admin/DeviceList';
import AdminDomainsList from '@/components/AdminDomainsList';
import Players from '@/pages/admin/Players';
import { unleashFeatureEnabled, UnleashKeys } from '@/lib/unleash';
import Alert from '@/components/ui/Alert.vue';

// BootstrapVue tabs are 0-indexed
const tabMapping = {
  organizations: 0,
  raffles: 1,
  orders: 2,
  users: 3,
  devices: 4,
  invoices: 5,
  'invoices-new': 6,
  domains: 7,
  tags: 8,
  players: 9
};

export default {
  components: {
    Alert,
    UserList,
    OrganizationsTable,
    AdminRaffleList,
    Invoices,
    Tags,
    Orders,
    DeviceList,
    InvoicesQBO,
    AdminDomainsList,
    Players
  },
  data() {
    return {
      tagsTableFilter: {
        system: true
      },
      errorMessage: '',
      successMessage: '',
      isRbAdmin: false,
      showQuickbooksInvoices: false,
      showManageDomains: false,
      showAdminInvoices: false,
      showAdminOrganizations: false,
      showAdminOrders: false,
      showAdminUsers: false,
      showAdminRaffles: false,
      showAdminDevices: false,
      showPlayers: false,
      currentTab: null,
      isSorting: false
    };
  },
  watch: {
    '$route.hash'(newValue, oldValue) {
      // If sorting is true, don't reset the sidebar
      if (this.isSorting === true) {
        return;
      }

      if (!newValue) {
        this.currentTab = tabMapping['organizations'];
      } else if (newValue !== oldValue) {
        this.currentTab = tabMapping[newValue.replace('#', '')];
      }
    },
    currentTab(newTab, oldTab) {
      const hash = tabMapping[this.$route.hash.replace('#', '')];

      // To prevent duplicate navigation, only replace the hash if the URL hash is not the same as the new tab, and the new tab is not the same as the old tab
      if (hash !== newTab && newTab !== oldTab && oldTab !== null) {
        this.$router.replace(`/admin#${Object.keys(tabMapping).find((key) => tabMapping[key] === newTab)}`);
      }
    }
  },
  methods: {
    handleSort(isSorting) {
      this.isSorting = isSorting;
    },
    onCreateTag(event) {
      if (event instanceof Error) {
        this.errorMessage = this.parseError(event).message;
      } else {
        this.successMessage = 'Successfully created tag';
        this.$refs.tagsTable.refreshTable();
      }
    },
    handleError(error) {
      this.errorMessage = this.parseError(error).message;
    }
  },
  async mounted() {
    this.showQuickbooksInvoices = await unleashFeatureEnabled(UnleashKeys.QuickbooksInvoices);
    this.showManageDomains = await unleashFeatureEnabled(UnleashKeys.ManageDomains);
    this.showAdminInvoices = await unleashFeatureEnabled(UnleashKeys.AdminInvoices);
    this.showAdminOrganizations = await unleashFeatureEnabled(UnleashKeys.AdminOrganizations);
    this.showAdminOrders = await unleashFeatureEnabled(UnleashKeys.AdminOrders);
    this.showAdminUsers = await unleashFeatureEnabled(UnleashKeys.AdminUsers);
    this.showAdminRaffles = await unleashFeatureEnabled(UnleashKeys.AdminRaffles);
    this.showAdminDevices = await unleashFeatureEnabled(UnleashKeys.AdminDevices);
    this.showPlayers = await unleashFeatureEnabled(UnleashKeys.ViewPlayers);

    if (!this.$route.hash) {
      this.$router.replace('/admin#organizations');
    } else {
      this.currentTab = tabMapping[this.$route.hash.replace('#', '')];
    }
  },
  async created() {
    const sessionUser = await getAuth().sessionUser();
    // Render of `<template />` is sync. Therefore we save the role in the component’s data, then render that data.
    this.isRbAdmin = sessionUser.hasRole(Roles.RB_ADMIN);
  }
};
</script>

<style scoped>
.container {
  text-align: left;
  margin-left: 115px;
  max-width: 90%;
}

.organization-name {
  font-size: 2.5rem;
  line-height: 1.2;
}

.organization-name + button {
  margin-left: 1em;
}

.title-max-width {
  max-width: 50%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.title-max-width + button {
  margin-top: 5px;
  margin-bottom: auto;
}

.title-max-width:hover {
  max-width: 70%;
  white-space: inherit;
}

a {
  text-decoration: none;
  color: black;
}
.orders-page {
  margin-top: 0px;
  margin-left: -20px;
  max-width: 100%;
}
</style>

<style>
.responsive {
  width: calc(100% - 320px);
}
</style>

<template>
  <b-modal
    :id="modalId"
    title="Edit Invoice"
    v-model="modalShow"
    @show="loadInvoiceForm"
    @ok="handleOk"
    no-close-on-backdrop
    size="xl"
    body-class="position-static"
  >
    <b-overlay :show="loading" no-wrap rounded="sm" />
    <InvoiceForm :invoice="invoice" v-if="invoice.id" />
  </b-modal>
</template>

<script>
import { zonedTimeToUtc, format } from 'date-fns-tz';

import InvoiceService from '@/lib/invoice-service-v2';

import InvoiceForm from '@/components/forms/InvoiceForm.vue';
import { parseISO } from 'date-fns/fp';

export default {
  props: ['modalId', 'invoiceId'],
  components: {
    InvoiceForm
  },
  data() {
    return {
      modalShow: false,
      errorMessage: null,
      loading: false,
      invoice: {
        id: null,
        name: null,
        eventId: null,
        organizationId: null,
        dueDate: null,
        dueTime: null,
        invoiceId: null,
        settings: {
          externalUrl: null
        },
        invoiceItems: [],
        discounts: [],
        organization: null,
        event: null
      }
    };
  },
  methods: {
    handleOk(event) {
      event.preventDefault();
      this.onSubmit();
    },
    async resetForm() {
      await this.loadInvoiceForm();
    },

    async loadInvoiceForm() {
      this.loading = true;

      const invoiceResponse = await InvoiceService.retrieveInvoice(this.invoiceId);
      const invoice = invoiceResponse.data;
      const invoiceItems = [];
      const discounts = [];

      for (const invoiceItem of invoice.invoiceItems) {
        if (invoiceItem.amountCents < 0) {
          discounts.push({
            id: invoiceItem.id,
            name: invoiceItem.name,
            type: 'amount',
            amount: ((invoiceItem.amountCents * -1) / 100).toFixed(2),
            amountCents: invoiceItem.amountCents
          });
        } else {
          invoiceItems.push({
            id: invoiceItem.id,
            name: invoiceItem.name,
            amount: (invoiceItem.amountCents / 100).toFixed(2)
          });
        }
      }

      const dueDate = parseISO(invoice.dueDate);

      this.invoice = {
        id: invoice.id,
        organizationId: invoice.organization.id,
        organization: invoice.organization,
        eventId: invoice.event?.id,
        event: invoice.event,
        name: invoice.name,
        dueDate: format(dueDate, 'yyyy-MM-dd'),
        dueTime: format(dueDate, 'HH:mm'),
        invoiceId: invoice.invoiceId,
        settings: invoice.settings || {},
        invoiceItems,
        discounts
      };

      this.loading = false;
    },

    async onSubmit() {
      this.loading = true;

      const dueDate = zonedTimeToUtc(`${this.invoice.dueDate} ${this.invoice.dueTime}`, this.timeZone);

      const formValid = await this.$validator.validateAll();

      if (!formValid) {
        return;
      }

      const data = {
        eventId: this.invoice.eventId,
        organizationId: this.invoice.organizationId,
        name: this.invoice.name,
        dueDate: dueDate,
        invoiceId: this.invoice.invoiceId,
        settings: this.invoice.settings
      };

      data.invoiceItems = this.invoice.invoiceItems.map((item) => {
        return { id: item.id, name: item.name, amountCents: Math.floor(item.amount * 100) };
      });

      for (const discount of this.invoice.discounts) {
        data.invoiceItems.push({
          id: discount.id,
          name: discount.name,
          amountCents: discount.amountCents * -1
        });
      }

      try {
        await InvoiceService.updateInvoice(this.invoice.id, data);

        this.modalShow = false;

        this.$emit('success');
      } catch (error) {
        if (error.response) {
          this.errorMessage = error.response.data.errors[0].message;
        } else {
          this.errorMessage = 'An unexpected error occurred, please try again later.';
        }
      }

      this.loading = false;
    }
  }
};
</script>

<style scoped>
.type-column {
  width: 20%;
  margin-right: 8px;
}
.name-column {
  width: 45%;
  margin-right: 8px;
}
.amount-column {
  width: 45%;
  margin-right: 8px;
}
.remove-column {
  width: 10%;
}
</style>

<template>
  <div>
    <b-form @submit.stop.prevent>
      <b-form-group label-for="input-tag-name">
        <b-form-input
          name="input-tag-name"
          v-model="tag.name"
          v-validate="{ required: true, min: 3, max: 25, regex: '^[a-zA-Z0-9]+((-|\\s)[a-zA-Z0-9]+)?$' }"
          aria-describedby="input-tag-name-feedback"
          data-vv-as="tag name"
          placeholder="Enter tag name"
          class="d-inline col-3 mr-2"
          trim
        />
        <b-button style="margin-top: -4px" variant="success" @click="onSubmit">Save</b-button>
      </b-form-group>
    </b-form>
  </div>
</template>
<script>
import { getAuth } from '@rafflebox-technologies-inc/auth-service-sdk';

import TagServiceV2 from '@/lib/tag-service-v2';

export default {
  data() {
    return {
      tag: {
        name: ''
      }
    };
  },
  methods: {
    resetForm() {
      this.tag.name = '';

      this.$nextTick(() => {
        this.$validator.reset();
      });
    },
    async onSubmit() {
      const valid = await this.$validator.validateAll();
      if (!valid) {
        return;
      }

      try {
        const sessionUser = await getAuth().sessionUser();
        const id = await TagServiceV2.createTag(sessionUser.organizationUuid, this.tag.name);

        this.$emit('onCreateTag', id);
        this.resetForm();
      } catch (error) {
        this.$emit('onError', error);
      }
    }
  }
};
</script>

<template>
  <b-dropdown-item :variant="variant" @click="bubbleClick">
    <b-row class="flex-nowrap">
      <b-col cols="2">
        <i :class="iconClass"></i>
      </b-col>
      <b-col cols="10"><slot></slot></b-col>
    </b-row>
  </b-dropdown-item>
</template>
<script>
export default {
  props: ['variant', 'icon'],
  data() {
    return {
      iconClass: `fa-solid fa-${this.icon}`
    };
  },
  methods: {
    bubbleClick() {
      this.$emit('click');
    }
  }
};
</script>

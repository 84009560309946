var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('GenericTable',{staticClass:"domains-table",attrs:{"columns":_vm.columns,"data":_vm.data,"loadMore":true,"loading":_vm.loading,"cannotLoadMore":!_vm.nextMarker,"totalName":"Displayed Domains"},on:{"loadMoreData":_vm.loadMoreData},scopedSlots:_vm._u([{key:"Name",fn:function(ref){
var row = ref.row;
return [_c('a',{staticClass:"text-gray-800 button-link underline",attrs:{"href":'http://www.' + row.Name.slice(0, -1),"target":"_blank"}},[_vm._v(" "+_vm._s(row.Name.slice(0, -1))+" ")])]}},{key:"Organization",fn:function(ref){
var row = ref.row;
return [(row.Organization)?_c('router-link',{staticClass:"text-gray-800 button-link underline",attrs:{"to":{ path: ("admin/organization?id=" + (row.Comment)) }}},[_vm._v(" "+_vm._s(row.Organization)+" ")]):_c('p',{class:!row.Organization && 'text-gray-400'},[_vm._v("Unassigned")])]}},{key:"CreationDate",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"dotted-underline",attrs:{"id":("domain-" + (row.Id) + "-1")}},[_vm._v(" "+_vm._s(row.CreationDate ? _vm.format(new Date(row.CreationDate), 'yyyy-MM-dd') : '')+" ")]),(row.CreationDate)?_c('b-tooltip',{attrs:{"target":("domain-" + (row.Id) + "-1"),"triggers":"hover"}},[_vm._v(" "+_vm._s(_vm.formatDateTime(_vm.parseISO(row.CreationDate), { format: 'dateTime', timeZone: _vm.sessionUserTimeZone, displayTimeZone: true }))+" ")]):_vm._e()]}},{key:"ExpirationDate",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"dotted-underline",attrs:{"id":("domain-" + (row.Id) + "-2")}},[_vm._v(" "+_vm._s(row.ExpirationDate ? _vm.format(new Date(row.ExpirationDate), 'yyyy-MM-dd') : '')+" ")]),(row.ExpirationDate)?_c('b-tooltip',{attrs:{"target":("domain-" + (row.Id) + "-2"),"triggers":"hover"}},[_vm._v(" "+_vm._s(_vm.formatDateTime(_vm.parseISO(row.ExpirationDate), { format: 'dateTime', timeZone: _vm.sessionUserTimeZone, displayTimeZone: true }))+" ")]):_vm._e()]}},{key:"AutoRenew",fn:function(ref){
var row = ref.row;
return [_c('Badge',{attrs:{"variant":row.AutoRenew ? 'success' : 'danger',"label":row.AutoRenew ? 'Yes' : 'No'}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="generate-link">
    <LoadingButton v-b-modal.sales-link-modal>Sign-up Link</LoadingButton>
    <b-modal id="sales-link-modal" title="Create Sign-up Link" @show="clearSelect" ok-only no-close-on-backdrop>
      <Alert variant="purple" class="information">
        This link can be provided to new Organizations. <br />
        The Sales person selected here will be associated with the new organization when they complete the form.
      </Alert>
      <SalesRepDropdownInput v-model="selectedSalesRep" @fullSelection="generateLink" required />
      <div
        id="copy-link"
        v-show="selectedSalesRep"
        @click="copyToClipboard"
        class="
          relative
          pl-4
          pr-8
          py-2
          border border-gray-300
          bg-gray-200
          hover:bg-gray-100
          rounded-lg
          cursor-pointer
          link-wrap
        "
      >
        <span>{{ link }}</span>
        <span class="absolute top-0 right-0 m-2">
          <font-awesome-icon class="h-5 w-5 text-gray-400 text-xl" :icon="['far', 'copy']" />
        </span>
      </div>
      <b-tooltip target="copy-link" :title="copyMsg"></b-tooltip>
    </b-modal>
  </div>
</template>

<script>
import Alert from '@/components/ui/Alert';
import LoadingButton from '@/components/ui/LoadingButton';
import SalesRepDropdownInput from '@/components/inputs/SalesRepDropdownInput';
import config from '@/config';

export default {
  components: {
    Alert,
    LoadingButton,
    SalesRepDropdownInput
  },
  data() {
    return {
      selectedSalesRep: null,
      link: null,
      copyMsg: 'Click to Copy'
    };
  },
  provide() {
    return { parentValidator: this.$validator };
  },
  methods: {
    clearSelect() {
      this.selectedSalesRep = null;
    },
    generateLink(salesRep) {
      if (salesRep.length) {
        const name = encodeURIComponent(salesRep[0].text);
        const id = salesRep[0].value;

        const link = `${config.RAFFLEBOX_DASHBOARD_URL}/sign-up?salesrepname=${name}&salesrepid=${id}`;

        this.link = link;
      }
    },
    copyToClipboard() {
      navigator.clipboard.writeText(this.link).then(
        () => {
          this.copyMsg = 'Copied!';
        },
        (error) => {
          this.copyMsg = 'Failed to Copy';
          console.log('Failed to copy', error);
        }
      );

      setTimeout(() => {
        this.copyMsg = 'Click to Copy';
      }, 500);
    }
  }
};
</script>

import Axios from '@/axios';

const baseRoute = '/quick-books-service/v2';

const listQuickbookInvoices = async (params) => {
  const response = await Axios.get(`${baseRoute}/quickbook-invoices`, { params });
  return response.data.data;
};

const getInvoicePDF = async (params) => {
  const response = await Axios.get(`${baseRoute}/quickbook-invoice-pdf`, { params });
  return response.data.data.pdf;
};

export default { listQuickbookInvoices, getInvoicePDF };

<template>
  <div>
    <b-button v-b-modal.create-invoice-modal variant="outline-secondary"> Create Invoice </b-button>

    <b-modal
      id="create-invoice-modal"
      title="Create Invoice"
      v-model="modalShow"
      @show="resetForm"
      @hidden="resetForm"
      @ok="handleOk"
      no-close-on-backdrop
      size="xl"
      body-class="position-static"
    >
      <b-overlay :show="loading" no-wrap rounded="sm" />
      <InvoiceForm :invoice="invoice" />
    </b-modal>
  </div>
</template>

<script>
import { zonedTimeToUtc } from 'date-fns-tz';

import InvoiceService from '@/lib/invoice-service-v2';

import InvoiceForm from '@/components/forms/InvoiceForm.vue';

export default {
  components: {
    InvoiceForm
  },
  data() {
    return {
      modalShow: false,
      errorMessage: null,
      loading: false,
      invoice: {
        name: null,
        eventId: null,
        organizationId: null,
        dueDate: null,
        dueTime: null,
        invoiceId: null,
        settings: {
          externalUrl: null
        },
        invoiceItems: [],
        discounts: [],
        event: null,
        organization: null
      }
    };
  },
  methods: {
    handleOk(event) {
      event.preventDefault();
      this.onSubmit();
    },
    resetForm() {
      this.invoice = {
        name: null,
        eventId: null,
        organizationId: null,
        dueDate: null,
        dueTime: null,
        invoiceId: null,
        settings: {
          externalUrl: null
        },
        invoiceItems: [],
        discounts: [],
        event: null,
        organization: null
      };
    },

    async onSubmit() {
      this.loading = true;

      const dueDate = zonedTimeToUtc(`${this.invoice.dueDate} ${this.invoice.dueTime}`, this.timeZone);

      const formValid = await this.$validator.validateAll();

      if (!formValid) {
        return;
      }

      const data = {
        eventId: this.invoice.eventId,
        organizationId: this.invoice.organizationId,
        name: this.invoice.name,
        dueDate: dueDate,
        invoiceId: this.invoice.invoiceId,
        settings: this.invoice.settings
      };

      data.invoiceItems = this.invoice.invoiceItems.map((item) => {
        return { name: item.name, amountCents: Math.round(item.amount * 100) };
      });

      for (const discount of this.invoice.discounts) {
        data.invoiceItems.push({
          name: discount.name,
          amountCents: discount.amountCents * -1
        });
      }

      try {
        await InvoiceService.createInvoice(data);

        this.modalShow = false;

        this.$emit('invoiceCreated');
      } catch (error) {
        if (error.response) {
          this.errorMessage = error.response.data.errors[0].message;
        } else {
          this.errorMessage = 'An unexpected error occurred, please try again later.';
        }
      }

      this.loading = false;
    }
  }
};
</script>

<style scoped>
.type-column {
  width: 20%;
  margin-right: 8px;
}
.name-column {
  width: 45%;
  margin-right: 8px;
}
.amount-column {
  width: 45%;
  margin-right: 8px;
}
.remove-column {
  width: 10%;
}
</style>
